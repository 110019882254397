import axios from '../utils/axios';

import {fetchNotificationNotificationListStart, fetchNotificationNotificationListSuccess, fetchNotificationNotificationListFailure} from '../features/notification'
import {fetchNotificationNotificationItemStart, fetchNotificationNotificationItemSuccess, fetchNotificationNotificationItemFailure} from '../features/notification'
import {createNotificationNotificationItemStart, createNotificationNotificationItemSuccess, createNotificationNotificationItemFailure} from '../features/notification'
import {updateNotificationNotificationItemStart, updateNotificationNotificationItemSuccess, updateNotificationNotificationItemFailure} from '../features/notification'
import {deleteNotificationNotificationItemStart, deleteNotificationNotificationItemSuccess, deleteNotificationNotificationItemFailure} from '../features/notification'

import {fetchNotificationNotificationtypeListStart, fetchNotificationNotificationtypeListSuccess, fetchNotificationNotificationtypeListFailure} from '../features/notification'
import {fetchNotificationNotificationtypeItemStart, fetchNotificationNotificationtypeItemSuccess, fetchNotificationNotificationtypeItemFailure} from '../features/notification'
import {createNotificationNotificationtypeItemStart, createNotificationNotificationtypeItemSuccess, createNotificationNotificationtypeItemFailure} from '../features/notification'
import {updateNotificationNotificationtypeItemStart, updateNotificationNotificationtypeItemSuccess, updateNotificationNotificationtypeItemFailure} from '../features/notification'
import {deleteNotificationNotificationtypeItemStart, deleteNotificationNotificationtypeItemSuccess, deleteNotificationNotificationtypeItemFailure} from '../features/notification'

import {fetchNotificationUsernotificationsettingsListStart, fetchNotificationUsernotificationsettingsListSuccess, fetchNotificationUsernotificationsettingsListFailure} from '../features/notification'
import {fetchNotificationUsernotificationsettingsItemStart, fetchNotificationUsernotificationsettingsItemSuccess, fetchNotificationUsernotificationsettingsItemFailure} from '../features/notification'
import {createNotificationUsernotificationsettingsItemStart, createNotificationUsernotificationsettingsItemSuccess, createNotificationUsernotificationsettingsItemFailure} from '../features/notification'
import {updateNotificationUsernotificationsettingsItemStart, updateNotificationUsernotificationsettingsItemSuccess, updateNotificationUsernotificationsettingsItemFailure} from '../features/notification'
import {deleteNotificationUsernotificationsettingsItemStart, deleteNotificationUsernotificationsettingsItemSuccess, deleteNotificationUsernotificationsettingsItemFailure} from '../features/notification'


// NOTIFICATION ACTIONS
export const fetchNotificationNotificationList = async (dispatch, info, key) => {
    try{
        dispatch(fetchNotificationNotificationListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/notification/notification/`, { params: info.params, headers: info.headers });
        dispatch(fetchNotificationNotificationListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchNotificationNotificationListFailure({key, error: error.message}));
    }
}
export const fetchNotificationNotificationItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchNotificationNotificationItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/notification/notification/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchNotificationNotificationItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchNotificationNotificationItemFailure({key, error: error.message}));
    }
}
export const createNotificationNotificationItem = async (dispatch, info, key) => {
    try{
        dispatch(createNotificationNotificationItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/notification/notification/`, info.payload, { headers: info.headers });
        dispatch(createNotificationNotificationItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createNotificationNotificationItemFailure({key, error: error.message}));
    }
}
export const updateNotificationNotificationItem = async (dispatch, info, key) => {
    try{
        dispatch(updateNotificationNotificationItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/notification/notification/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateNotificationNotificationItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateNotificationNotificationItemFailure({key, error: error.message}));
    }
}
export const deleteNotificationNotificationItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteNotificationNotificationItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/notification/notification/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteNotificationNotificationItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteNotificationNotificationItemFailure({key, error: error.message}));
    }
}


// NOTIFICATIONTYPE ACTIONS
export const fetchNotificationNotificationtypeList = async (dispatch, info, key) => {
    try{
        dispatch(fetchNotificationNotificationtypeListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/notification/notificationtype/`, { params: info.params, headers: info.headers });
        dispatch(fetchNotificationNotificationtypeListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchNotificationNotificationtypeListFailure({key, error: error.message}));
    }
}
export const fetchNotificationNotificationtypeItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchNotificationNotificationtypeItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/notification/notificationtype/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchNotificationNotificationtypeItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchNotificationNotificationtypeItemFailure({key, error: error.message}));
    }
}
export const createNotificationNotificationtypeItem = async (dispatch, info, key) => {
    try{
        dispatch(createNotificationNotificationtypeItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/notification/notificationtype/`, info.payload, { headers: info.headers });
        dispatch(createNotificationNotificationtypeItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createNotificationNotificationtypeItemFailure({key, error: error.message}));
    }
}
export const updateNotificationNotificationtypeItem = async (dispatch, info, key) => {
    try{
        dispatch(updateNotificationNotificationtypeItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/notification/notificationtype/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateNotificationNotificationtypeItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateNotificationNotificationtypeItemFailure({key, error: error.message}));
    }
}
export const deleteNotificationNotificationtypeItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteNotificationNotificationtypeItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/notification/notificationtype/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteNotificationNotificationtypeItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteNotificationNotificationtypeItemFailure({key, error: error.message}));
    }
}


// USERNOTIFICATIONSETTINGS ACTIONS
export const fetchNotificationUsernotificationsettingsList = async (dispatch, info, key) => {
    try{
        dispatch(fetchNotificationUsernotificationsettingsListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/notification/usernotificationsettings/`, { params: info.params, headers: info.headers });
        dispatch(fetchNotificationUsernotificationsettingsListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchNotificationUsernotificationsettingsListFailure({key, error: error.message}));
    }
}
export const fetchNotificationUsernotificationsettingsItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchNotificationUsernotificationsettingsItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/notification/usernotificationsettings/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchNotificationUsernotificationsettingsItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchNotificationUsernotificationsettingsItemFailure({key, error: error.message}));
    }
}
export const createNotificationUsernotificationsettingsItem = async (dispatch, info, key) => {
    try{
        dispatch(createNotificationUsernotificationsettingsItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/notification/usernotificationsettings/`, info.payload, { headers: info.headers });
        dispatch(createNotificationUsernotificationsettingsItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createNotificationUsernotificationsettingsItemFailure({key, error: error.message}));
    }
}
export const updateNotificationUsernotificationsettingsItem = async (dispatch, info, key) => {
    try{
        dispatch(updateNotificationUsernotificationsettingsItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/notification/usernotificationsettings/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateNotificationUsernotificationsettingsItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateNotificationUsernotificationsettingsItemFailure({key, error: error.message}));
    }
}
export const deleteNotificationUsernotificationsettingsItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteNotificationUsernotificationsettingsItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/notification/usernotificationsettings/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteNotificationUsernotificationsettingsItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteNotificationUsernotificationsettingsItemFailure({key, error: error.message}));
    }
}




