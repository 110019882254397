import axios from '../utils/axios';

import {fetchPaymentPaymentmethodListStart, fetchPaymentPaymentmethodListSuccess, fetchPaymentPaymentmethodListFailure} from '../features/payment'
import {fetchPaymentPaymentmethodItemStart, fetchPaymentPaymentmethodItemSuccess, fetchPaymentPaymentmethodItemFailure} from '../features/payment'
import {createPaymentPaymentmethodItemStart, createPaymentPaymentmethodItemSuccess, createPaymentPaymentmethodItemFailure} from '../features/payment'
import {updatePaymentPaymentmethodItemStart, updatePaymentPaymentmethodItemSuccess, updatePaymentPaymentmethodItemFailure} from '../features/payment'
import {deletePaymentPaymentmethodItemStart, deletePaymentPaymentmethodItemSuccess, deletePaymentPaymentmethodItemFailure} from '../features/payment'

import {fetchPaymentPaymenttransactionListStart, fetchPaymentPaymenttransactionListSuccess, fetchPaymentPaymenttransactionListFailure} from '../features/payment'
import {fetchPaymentPaymenttransactionItemStart, fetchPaymentPaymenttransactionItemSuccess, fetchPaymentPaymenttransactionItemFailure} from '../features/payment'
import {createPaymentPaymenttransactionItemStart, createPaymentPaymenttransactionItemSuccess, createPaymentPaymenttransactionItemFailure} from '../features/payment'
import {updatePaymentPaymenttransactionItemStart, updatePaymentPaymenttransactionItemSuccess, updatePaymentPaymenttransactionItemFailure} from '../features/payment'
import {deletePaymentPaymenttransactionItemStart, deletePaymentPaymenttransactionItemSuccess, deletePaymentPaymenttransactionItemFailure} from '../features/payment'


// PAYMENTMETHOD ACTIONS
export const fetchPaymentPaymentmethodList = async (dispatch, info, key) => {
    try{
        dispatch(fetchPaymentPaymentmethodListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/payment/paymentmethod/`, { params: info.params, headers: info.headers });
        dispatch(fetchPaymentPaymentmethodListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchPaymentPaymentmethodListFailure({key, error: error.message}));
    }
}
export const fetchPaymentPaymentmethodItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchPaymentPaymentmethodItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/payment/paymentmethod/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchPaymentPaymentmethodItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchPaymentPaymentmethodItemFailure({key, error: error.message}));
    }
}
export const createPaymentPaymentmethodItem = async (dispatch, info, key) => {
    try{
        dispatch(createPaymentPaymentmethodItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/payment/paymentmethod/`, info.payload, { headers: info.headers });
        dispatch(createPaymentPaymentmethodItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createPaymentPaymentmethodItemFailure({key, error: error.message}));
    }
}
export const updatePaymentPaymentmethodItem = async (dispatch, info, key) => {
    try{
        dispatch(updatePaymentPaymentmethodItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/payment/paymentmethod/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updatePaymentPaymentmethodItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updatePaymentPaymentmethodItemFailure({key, error: error.message}));
    }
}
export const deletePaymentPaymentmethodItem = async (dispatch, info, key) => {
    try{
        dispatch(deletePaymentPaymentmethodItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/payment/paymentmethod/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deletePaymentPaymentmethodItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deletePaymentPaymentmethodItemFailure({key, error: error.message}));
    }
}


// PAYMENTTRANSACTION ACTIONS
export const fetchPaymentPaymenttransactionList = async (dispatch, info, key) => {
    try{
        dispatch(fetchPaymentPaymenttransactionListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/payment/paymenttransaction/`, { params: info.params, headers: info.headers });
        dispatch(fetchPaymentPaymenttransactionListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchPaymentPaymenttransactionListFailure({key, error: error.message}));
    }
}
export const fetchPaymentPaymenttransactionItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchPaymentPaymenttransactionItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/payment/paymenttransaction/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchPaymentPaymenttransactionItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchPaymentPaymenttransactionItemFailure({key, error: error.message}));
    }
}
export const createPaymentPaymenttransactionItem = async (dispatch, info, key) => {
    try{
        dispatch(createPaymentPaymenttransactionItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/payment/paymenttransaction/`, info.payload, { headers: info.headers });
        dispatch(createPaymentPaymenttransactionItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createPaymentPaymenttransactionItemFailure({key, error: error.message}));
    }
}
export const updatePaymentPaymenttransactionItem = async (dispatch, info, key) => {
    try{
        dispatch(updatePaymentPaymenttransactionItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/payment/paymenttransaction/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updatePaymentPaymenttransactionItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updatePaymentPaymenttransactionItemFailure({key, error: error.message}));
    }
}
export const deletePaymentPaymenttransactionItem = async (dispatch, info, key) => {
    try{
        dispatch(deletePaymentPaymenttransactionItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/payment/paymenttransaction/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deletePaymentPaymenttransactionItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deletePaymentPaymenttransactionItemFailure({key, error: error.message}));
    }
}




