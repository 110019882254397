import { createSlice } from '@reduxjs/toolkit';

const stateLoadingStart = (state, action) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: true, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: null };
}
const stateError = (state, action) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: false, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: action.payload.error };
}
const stateSuccess = (state, action, key) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: false, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: null, [key]: action.payload.data };
}

export const slice = createSlice({
  name: 'pricing',
  initialState: { loading: false },
  reducers: {
    
    // TAX REDUCER FUNCTION

    // TAX FETCH LIST
    fetchPricingTaxListStart: stateLoadingStart,
    fetchPricingTaxListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `tax_list`),
    fetchPricingTaxListFailure: stateError,

    // TAX FETCH ITEM
    fetchPricingTaxItemStart: stateLoadingStart,
    fetchPricingTaxItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `tax_item`),
    fetchPricingTaxItemFailure: stateError,

    // TAX CREATE ITEM
    createPricingTaxItemStart: stateLoadingStart,
    createPricingTaxItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `tax_item`),
    createPricingTaxItemFailure: stateError,

    // TAX UPDATE ITEM
    updatePricingTaxItemStart: stateLoadingStart,
    updatePricingTaxItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `tax_item`),
    updatePricingTaxItemFailure: stateError,

    // TAX DELETE ITEM
    deletePricingTaxItemStart: stateLoadingStart,
    deletePricingTaxItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_tax`),
    deletePricingTaxItemFailure: stateError,


    // DISCOUNT REDUCER FUNCTION

    // DISCOUNT FETCH LIST
    fetchPricingDiscountListStart: stateLoadingStart,
    fetchPricingDiscountListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `discount_list`),
    fetchPricingDiscountListFailure: stateError,

    // DISCOUNT FETCH ITEM
    fetchPricingDiscountItemStart: stateLoadingStart,
    fetchPricingDiscountItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `discount_item`),
    fetchPricingDiscountItemFailure: stateError,

    // DISCOUNT CREATE ITEM
    createPricingDiscountItemStart: stateLoadingStart,
    createPricingDiscountItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `discount_item`),
    createPricingDiscountItemFailure: stateError,

    // DISCOUNT UPDATE ITEM
    updatePricingDiscountItemStart: stateLoadingStart,
    updatePricingDiscountItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `discount_item`),
    updatePricingDiscountItemFailure: stateError,

    // DISCOUNT DELETE ITEM
    deletePricingDiscountItemStart: stateLoadingStart,
    deletePricingDiscountItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_discount`),
    deletePricingDiscountItemFailure: stateError,


    // COUPON REDUCER FUNCTION

    // COUPON FETCH LIST
    fetchPricingCouponListStart: stateLoadingStart,
    fetchPricingCouponListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `coupon_list`),
    fetchPricingCouponListFailure: stateError,

    // COUPON FETCH ITEM
    fetchPricingCouponItemStart: stateLoadingStart,
    fetchPricingCouponItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `coupon_item`),
    fetchPricingCouponItemFailure: stateError,

    // COUPON CREATE ITEM
    createPricingCouponItemStart: stateLoadingStart,
    createPricingCouponItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `coupon_item`),
    createPricingCouponItemFailure: stateError,

    // COUPON UPDATE ITEM
    updatePricingCouponItemStart: stateLoadingStart,
    updatePricingCouponItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `coupon_item`),
    updatePricingCouponItemFailure: stateError,

    // COUPON DELETE ITEM
    deletePricingCouponItemStart: stateLoadingStart,
    deletePricingCouponItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_coupon`),
    deletePricingCouponItemFailure: stateError,


  },
});

export const {
  fetchPricingTaxListStart, fetchPricingDiscountListStart, fetchPricingCouponListStart,
  fetchPricingTaxListSuccess, fetchPricingDiscountListSuccess, fetchPricingCouponListSuccess,
  fetchPricingTaxListFailure, fetchPricingDiscountListFailure, fetchPricingCouponListFailure,
  fetchPricingTaxItemStart, fetchPricingDiscountItemStart, fetchPricingCouponItemStart,
  fetchPricingTaxItemSuccess, fetchPricingDiscountItemSuccess, fetchPricingCouponItemSuccess,
  fetchPricingTaxItemFailure, fetchPricingDiscountItemFailure, fetchPricingCouponItemFailure,
  createPricingTaxItemStart, createPricingDiscountItemStart, createPricingCouponItemStart,
  createPricingTaxItemSuccess, createPricingDiscountItemSuccess, createPricingCouponItemSuccess,
  createPricingTaxItemFailure, createPricingDiscountItemFailure, createPricingCouponItemFailure,
  updatePricingTaxItemStart, updatePricingDiscountItemStart, updatePricingCouponItemStart,
  updatePricingTaxItemSuccess, updatePricingDiscountItemSuccess, updatePricingCouponItemSuccess,
  updatePricingTaxItemFailure, updatePricingDiscountItemFailure, updatePricingCouponItemFailure,
  deletePricingTaxItemStart, deletePricingDiscountItemStart, deletePricingCouponItemStart,
  deletePricingTaxItemSuccess, deletePricingDiscountItemSuccess, deletePricingCouponItemSuccess,
  deletePricingTaxItemFailure, deletePricingDiscountItemFailure, deletePricingCouponItemFailure
} = slice.actions;

export default slice.reducer;
