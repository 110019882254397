import { createSlice } from '@reduxjs/toolkit';

const stateLoadingStart = (state, action) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: true, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: null };
}
const stateError = (state, action) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: false, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: action.payload.error };
}
const stateSuccess = (state, action, key) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: false, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: null, [key]: action.payload.data };
}

export const slice = createSlice({
  name: 'product',
  initialState: { loading: false },
  reducers: {
    
    // CATEGORY REDUCER FUNCTION

    // CATEGORY FETCH LIST
    fetchProductCategoryListStart: stateLoadingStart,
    fetchProductCategoryListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `category_list`),
    fetchProductCategoryListFailure: stateError,

    // CATEGORY FETCH ITEM
    fetchProductCategoryItemStart: stateLoadingStart,
    fetchProductCategoryItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `category_item`),
    fetchProductCategoryItemFailure: stateError,

    // CATEGORY CREATE ITEM
    createProductCategoryItemStart: stateLoadingStart,
    createProductCategoryItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `category_item`),
    createProductCategoryItemFailure: stateError,

    // CATEGORY UPDATE ITEM
    updateProductCategoryItemStart: stateLoadingStart,
    updateProductCategoryItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `category_item`),
    updateProductCategoryItemFailure: stateError,

    // CATEGORY DELETE ITEM
    deleteProductCategoryItemStart: stateLoadingStart,
    deleteProductCategoryItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_category`),
    deleteProductCategoryItemFailure: stateError,


    // PRODUCT REDUCER FUNCTION

    // PRODUCT FETCH LIST
    fetchProductProductListStart: stateLoadingStart,
    fetchProductProductListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `product_list`),
    fetchProductProductListFailure: stateError,

    // PRODUCT FETCH ITEM
    fetchProductProductItemStart: stateLoadingStart,
    fetchProductProductItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `product_item`),
    fetchProductProductItemFailure: stateError,

    // PRODUCT CREATE ITEM
    createProductProductItemStart: stateLoadingStart,
    createProductProductItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `product_item`),
    createProductProductItemFailure: stateError,

    // PRODUCT UPDATE ITEM
    updateProductProductItemStart: stateLoadingStart,
    updateProductProductItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `product_item`),
    updateProductProductItemFailure: stateError,

    // PRODUCT DELETE ITEM
    deleteProductProductItemStart: stateLoadingStart,
    deleteProductProductItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_product`),
    deleteProductProductItemFailure: stateError,


  },
});

export const {
  fetchProductCategoryListStart, fetchProductProductListStart,
  fetchProductCategoryListSuccess, fetchProductProductListSuccess,
  fetchProductCategoryListFailure, fetchProductProductListFailure,
  fetchProductCategoryItemStart, fetchProductProductItemStart,
  fetchProductCategoryItemSuccess, fetchProductProductItemSuccess,
  fetchProductCategoryItemFailure, fetchProductProductItemFailure,
  createProductCategoryItemStart, createProductProductItemStart,
  createProductCategoryItemSuccess, createProductProductItemSuccess,
  createProductCategoryItemFailure, createProductProductItemFailure,
  updateProductCategoryItemStart, updateProductProductItemStart,
  updateProductCategoryItemSuccess, updateProductProductItemSuccess,
  updateProductCategoryItemFailure, updateProductProductItemFailure,
  deleteProductCategoryItemStart, deleteProductProductItemStart,
  deleteProductCategoryItemSuccess, deleteProductProductItemSuccess,
  deleteProductCategoryItemFailure, deleteProductProductItemFailure
} = slice.actions;

export default slice.reducer;
