import axios from '../utils/axios';

import {fetchPackagingPackagingtypeListStart, fetchPackagingPackagingtypeListSuccess, fetchPackagingPackagingtypeListFailure} from '../features/packaging'
import {fetchPackagingPackagingtypeItemStart, fetchPackagingPackagingtypeItemSuccess, fetchPackagingPackagingtypeItemFailure} from '../features/packaging'
import {createPackagingPackagingtypeItemStart, createPackagingPackagingtypeItemSuccess, createPackagingPackagingtypeItemFailure} from '../features/packaging'
import {updatePackagingPackagingtypeItemStart, updatePackagingPackagingtypeItemSuccess, updatePackagingPackagingtypeItemFailure} from '../features/packaging'
import {deletePackagingPackagingtypeItemStart, deletePackagingPackagingtypeItemSuccess, deletePackagingPackagingtypeItemFailure} from '../features/packaging'

import {fetchPackagingPackagingListStart, fetchPackagingPackagingListSuccess, fetchPackagingPackagingListFailure} from '../features/packaging'
import {fetchPackagingPackagingItemStart, fetchPackagingPackagingItemSuccess, fetchPackagingPackagingItemFailure} from '../features/packaging'
import {createPackagingPackagingItemStart, createPackagingPackagingItemSuccess, createPackagingPackagingItemFailure} from '../features/packaging'
import {updatePackagingPackagingItemStart, updatePackagingPackagingItemSuccess, updatePackagingPackagingItemFailure} from '../features/packaging'
import {deletePackagingPackagingItemStart, deletePackagingPackagingItemSuccess, deletePackagingPackagingItemFailure} from '../features/packaging'

import {fetchPackagingPackageditemListStart, fetchPackagingPackageditemListSuccess, fetchPackagingPackageditemListFailure} from '../features/packaging'
import {fetchPackagingPackageditemItemStart, fetchPackagingPackageditemItemSuccess, fetchPackagingPackageditemItemFailure} from '../features/packaging'
import {createPackagingPackageditemItemStart, createPackagingPackageditemItemSuccess, createPackagingPackageditemItemFailure} from '../features/packaging'
import {updatePackagingPackageditemItemStart, updatePackagingPackageditemItemSuccess, updatePackagingPackageditemItemFailure} from '../features/packaging'
import {deletePackagingPackageditemItemStart, deletePackagingPackageditemItemSuccess, deletePackagingPackageditemItemFailure} from '../features/packaging'


// PACKAGINGTYPE ACTIONS
export const fetchPackagingPackagingtypeList = async (dispatch, info, key) => {
    try{
        dispatch(fetchPackagingPackagingtypeListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/packaging/packagingtype/`, { params: info.params, headers: info.headers });
        dispatch(fetchPackagingPackagingtypeListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchPackagingPackagingtypeListFailure({key, error: error.message}));
    }
}
export const fetchPackagingPackagingtypeItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchPackagingPackagingtypeItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/packaging/packagingtype/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchPackagingPackagingtypeItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchPackagingPackagingtypeItemFailure({key, error: error.message}));
    }
}
export const createPackagingPackagingtypeItem = async (dispatch, info, key) => {
    try{
        dispatch(createPackagingPackagingtypeItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/packaging/packagingtype/`, info.payload, { headers: info.headers });
        dispatch(createPackagingPackagingtypeItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createPackagingPackagingtypeItemFailure({key, error: error.message}));
    }
}
export const updatePackagingPackagingtypeItem = async (dispatch, info, key) => {
    try{
        dispatch(updatePackagingPackagingtypeItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/packaging/packagingtype/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updatePackagingPackagingtypeItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updatePackagingPackagingtypeItemFailure({key, error: error.message}));
    }
}
export const deletePackagingPackagingtypeItem = async (dispatch, info, key) => {
    try{
        dispatch(deletePackagingPackagingtypeItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/packaging/packagingtype/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deletePackagingPackagingtypeItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deletePackagingPackagingtypeItemFailure({key, error: error.message}));
    }
}


// PACKAGING ACTIONS
export const fetchPackagingPackagingList = async (dispatch, info, key) => {
    try{
        dispatch(fetchPackagingPackagingListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/packaging/packaging/`, { params: info.params, headers: info.headers });
        dispatch(fetchPackagingPackagingListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchPackagingPackagingListFailure({key, error: error.message}));
    }
}
export const fetchPackagingPackagingItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchPackagingPackagingItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/packaging/packaging/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchPackagingPackagingItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchPackagingPackagingItemFailure({key, error: error.message}));
    }
}
export const createPackagingPackagingItem = async (dispatch, info, key) => {
    try{
        dispatch(createPackagingPackagingItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/packaging/packaging/`, info.payload, { headers: info.headers });
        dispatch(createPackagingPackagingItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createPackagingPackagingItemFailure({key, error: error.message}));
    }
}
export const updatePackagingPackagingItem = async (dispatch, info, key) => {
    try{
        dispatch(updatePackagingPackagingItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/packaging/packaging/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updatePackagingPackagingItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updatePackagingPackagingItemFailure({key, error: error.message}));
    }
}
export const deletePackagingPackagingItem = async (dispatch, info, key) => {
    try{
        dispatch(deletePackagingPackagingItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/packaging/packaging/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deletePackagingPackagingItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deletePackagingPackagingItemFailure({key, error: error.message}));
    }
}


// PACKAGEDITEM ACTIONS
export const fetchPackagingPackageditemList = async (dispatch, info, key) => {
    try{
        dispatch(fetchPackagingPackageditemListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/packaging/packageditem/`, { params: info.params, headers: info.headers });
        dispatch(fetchPackagingPackageditemListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchPackagingPackageditemListFailure({key, error: error.message}));
    }
}
export const fetchPackagingPackageditemItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchPackagingPackageditemItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/packaging/packageditem/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchPackagingPackageditemItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchPackagingPackageditemItemFailure({key, error: error.message}));
    }
}
export const createPackagingPackageditemItem = async (dispatch, info, key) => {
    try{
        dispatch(createPackagingPackageditemItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/packaging/packageditem/`, info.payload, { headers: info.headers });
        dispatch(createPackagingPackageditemItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createPackagingPackageditemItemFailure({key, error: error.message}));
    }
}
export const updatePackagingPackageditemItem = async (dispatch, info, key) => {
    try{
        dispatch(updatePackagingPackageditemItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/packaging/packageditem/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updatePackagingPackageditemItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updatePackagingPackageditemItemFailure({key, error: error.message}));
    }
}
export const deletePackagingPackageditemItem = async (dispatch, info, key) => {
    try{
        dispatch(deletePackagingPackageditemItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/packaging/packageditem/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deletePackagingPackageditemItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deletePackagingPackageditemItemFailure({key, error: error.message}));
    }
}




