// USAGE of action:
// action({dispatch, app, model, request_type, params})
//
// where:
// dispatch is value of useDispatch() imported as "import { useDispatch, useSelector } from 'react-redux'"
// app is the app name in django model
// model is the model name in django model
// request_type is the type of request. For example 'update', 'partial_update', 'read', 'delete', "create"
// request_info are the parameters or payload or filters
// auth is boolean which is either true or false

import { func_map } from "./func_map";
import { getRecentAccessToken } from "./tokenHandler";

const updateInfo = async (info, has_payload, auth) => {
  const info_body = {};
  const isFileType =
    Object.keys(info)
      .map(
        (key, i) =>
          info[key]?.name && info[key]?.type && info[key]?.size && "pass"
      )
      .filter((i) => i === "pass").length > 0;
  if (auth) {
    const fresh_token = await getRecentAccessToken();
    if (isFileType) {
      info_body.headers = {
        Authorization: `Bearer ${fresh_token}`,
        "Content-type": "multipart/form-data",
      };
    } else {
      info_body.headers = { Authorization: `Bearer ${fresh_token}` };
    }
  } else {
    if (isFileType) {
      info_body.headers = { "Content-type": "multipart/form-data" };
    }
  }
  if (has_payload) {
    const with_lookup = info.lookup_field
      ? {
          lookup_field: info.lookup_field,
          [info.lookup_field]: info[info.lookup_field],
        }
      : {};
    info_body.payload = info;
    info_body.params = {
      id: info.id,
      slug: info.slug,
      lookup_field: info.lookup_field,
      ...with_lookup,
    };
  } else {
    info_body.params = info;
  }
  return info_body;
};

let runningRequests = {};
const callAction = async (props) => {
  const { dispatch, request, request_info, auth, key } = props;
  const app = request[0];
  const model = request[1];
  const request_type = request[2];
  const model_postfix = request_type === "list" ? "List" : "Item";
  let model_prefix;

  if (["read", "list"].indexOf(request_type) > -1) model_prefix = "fetch";
  else if (["update", "partial_update"].indexOf(request_type) > -1)
    model_prefix = "update";
  else if (request_type === "create") model_prefix = "create";
  else if (request_type === "delete") model_prefix = "delete";

  const requestKey = `${model_postfix}${app}${model}${model_prefix}${JSON.stringify(
    request_info || {}
  )}${auth ? "true" : "false"}${key}`;

  const model_name =
    model.charAt(0).toUpperCase() + model.toLowerCase().slice(1);
  const app_name = app.charAt(0).toUpperCase() + app.toLowerCase().slice(1);
  if (model_postfix && app_name && model_name && model_prefix) {
    const selector = model_prefix + app_name + model_name + model_postfix;
    let updated_info;
    if (
      request_type === "list" ||
      request_type === "read" ||
      request_type === "delete"
    ) {
      updated_info = await updateInfo(request_info, false, auth);
    } else {
      updated_info = await updateInfo(request_info, true, auth);
    }

    if (!runningRequests[requestKey]) {
      runningRequests[requestKey] = func_map[selector](
        dispatch,
        updated_info,
        key
      );
      await runningRequests[requestKey];
      delete runningRequests[requestKey];
    }
    return runningRequests[requestKey];
  }
  return null;
};

const action = async (props) => {
  if (props.length && props.length > 0) {
    const promises = props.map(async (item, i) => {
      return await callAction(item);
    });
    return await Promise.all(promises);
  } else {
    return await callAction(props);
  }
};

export default action;
