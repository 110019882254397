import { createSlice } from '@reduxjs/toolkit';

const stateLoadingStart = (state, action) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: true, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: null };
}
const stateError = (state, action) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: false, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: action.payload.error };
}
const stateSuccess = (state, action, key) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: false, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: null, [key]: action.payload.data };
}

export const slice = createSlice({
  name: 'payment',
  initialState: { loading: false },
  reducers: {
    
    // PAYMENTMETHOD REDUCER FUNCTION

    // PAYMENTMETHOD FETCH LIST
    fetchPaymentPaymentmethodListStart: stateLoadingStart,
    fetchPaymentPaymentmethodListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `paymentmethod_list`),
    fetchPaymentPaymentmethodListFailure: stateError,

    // PAYMENTMETHOD FETCH ITEM
    fetchPaymentPaymentmethodItemStart: stateLoadingStart,
    fetchPaymentPaymentmethodItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `paymentmethod_item`),
    fetchPaymentPaymentmethodItemFailure: stateError,

    // PAYMENTMETHOD CREATE ITEM
    createPaymentPaymentmethodItemStart: stateLoadingStart,
    createPaymentPaymentmethodItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `paymentmethod_item`),
    createPaymentPaymentmethodItemFailure: stateError,

    // PAYMENTMETHOD UPDATE ITEM
    updatePaymentPaymentmethodItemStart: stateLoadingStart,
    updatePaymentPaymentmethodItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `paymentmethod_item`),
    updatePaymentPaymentmethodItemFailure: stateError,

    // PAYMENTMETHOD DELETE ITEM
    deletePaymentPaymentmethodItemStart: stateLoadingStart,
    deletePaymentPaymentmethodItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_paymentmethod`),
    deletePaymentPaymentmethodItemFailure: stateError,


    // PAYMENTTRANSACTION REDUCER FUNCTION

    // PAYMENTTRANSACTION FETCH LIST
    fetchPaymentPaymenttransactionListStart: stateLoadingStart,
    fetchPaymentPaymenttransactionListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `paymenttransaction_list`),
    fetchPaymentPaymenttransactionListFailure: stateError,

    // PAYMENTTRANSACTION FETCH ITEM
    fetchPaymentPaymenttransactionItemStart: stateLoadingStart,
    fetchPaymentPaymenttransactionItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `paymenttransaction_item`),
    fetchPaymentPaymenttransactionItemFailure: stateError,

    // PAYMENTTRANSACTION CREATE ITEM
    createPaymentPaymenttransactionItemStart: stateLoadingStart,
    createPaymentPaymenttransactionItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `paymenttransaction_item`),
    createPaymentPaymenttransactionItemFailure: stateError,

    // PAYMENTTRANSACTION UPDATE ITEM
    updatePaymentPaymenttransactionItemStart: stateLoadingStart,
    updatePaymentPaymenttransactionItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `paymenttransaction_item`),
    updatePaymentPaymenttransactionItemFailure: stateError,

    // PAYMENTTRANSACTION DELETE ITEM
    deletePaymentPaymenttransactionItemStart: stateLoadingStart,
    deletePaymentPaymenttransactionItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_paymenttransaction`),
    deletePaymentPaymenttransactionItemFailure: stateError,


  },
});

export const {
  fetchPaymentPaymentmethodListStart, fetchPaymentPaymenttransactionListStart,
  fetchPaymentPaymentmethodListSuccess, fetchPaymentPaymenttransactionListSuccess,
  fetchPaymentPaymentmethodListFailure, fetchPaymentPaymenttransactionListFailure,
  fetchPaymentPaymentmethodItemStart, fetchPaymentPaymenttransactionItemStart,
  fetchPaymentPaymentmethodItemSuccess, fetchPaymentPaymenttransactionItemSuccess,
  fetchPaymentPaymentmethodItemFailure, fetchPaymentPaymenttransactionItemFailure,
  createPaymentPaymentmethodItemStart, createPaymentPaymenttransactionItemStart,
  createPaymentPaymentmethodItemSuccess, createPaymentPaymenttransactionItemSuccess,
  createPaymentPaymentmethodItemFailure, createPaymentPaymenttransactionItemFailure,
  updatePaymentPaymentmethodItemStart, updatePaymentPaymenttransactionItemStart,
  updatePaymentPaymentmethodItemSuccess, updatePaymentPaymenttransactionItemSuccess,
  updatePaymentPaymentmethodItemFailure, updatePaymentPaymenttransactionItemFailure,
  deletePaymentPaymentmethodItemStart, deletePaymentPaymenttransactionItemStart,
  deletePaymentPaymentmethodItemSuccess, deletePaymentPaymenttransactionItemSuccess,
  deletePaymentPaymentmethodItemFailure, deletePaymentPaymenttransactionItemFailure
} = slice.actions;

export default slice.reducer;
