import axios from '../utils/axios';

import {fetchFeedbackFeedbackListStart, fetchFeedbackFeedbackListSuccess, fetchFeedbackFeedbackListFailure} from '../features/feedback'
import {fetchFeedbackFeedbackItemStart, fetchFeedbackFeedbackItemSuccess, fetchFeedbackFeedbackItemFailure} from '../features/feedback'
import {createFeedbackFeedbackItemStart, createFeedbackFeedbackItemSuccess, createFeedbackFeedbackItemFailure} from '../features/feedback'
import {updateFeedbackFeedbackItemStart, updateFeedbackFeedbackItemSuccess, updateFeedbackFeedbackItemFailure} from '../features/feedback'
import {deleteFeedbackFeedbackItemStart, deleteFeedbackFeedbackItemSuccess, deleteFeedbackFeedbackItemFailure} from '../features/feedback'


// FEEDBACK ACTIONS
export const fetchFeedbackFeedbackList = async (dispatch, info, key) => {
    try{
        dispatch(fetchFeedbackFeedbackListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/feedback/feedback/`, { params: info.params, headers: info.headers });
        dispatch(fetchFeedbackFeedbackListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchFeedbackFeedbackListFailure({key, error: error.message}));
    }
}
export const fetchFeedbackFeedbackItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchFeedbackFeedbackItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/feedback/feedback/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchFeedbackFeedbackItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchFeedbackFeedbackItemFailure({key, error: error.message}));
    }
}
export const createFeedbackFeedbackItem = async (dispatch, info, key) => {
    try{
        dispatch(createFeedbackFeedbackItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/feedback/feedback/`, info.payload, { headers: info.headers });
        dispatch(createFeedbackFeedbackItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createFeedbackFeedbackItemFailure({key, error: error.message}));
    }
}
export const updateFeedbackFeedbackItem = async (dispatch, info, key) => {
    try{
        dispatch(updateFeedbackFeedbackItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/feedback/feedback/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateFeedbackFeedbackItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateFeedbackFeedbackItemFailure({key, error: error.message}));
    }
}
export const deleteFeedbackFeedbackItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteFeedbackFeedbackItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/feedback/feedback/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteFeedbackFeedbackItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteFeedbackFeedbackItemFailure({key, error: error.message}));
    }
}




