import axios from '../utils/axios';

import {fetchOrderOrderListStart, fetchOrderOrderListSuccess, fetchOrderOrderListFailure} from '../features/order'
import {fetchOrderOrderItemStart, fetchOrderOrderItemSuccess, fetchOrderOrderItemFailure} from '../features/order'
import {createOrderOrderItemStart, createOrderOrderItemSuccess, createOrderOrderItemFailure} from '../features/order'
import {updateOrderOrderItemStart, updateOrderOrderItemSuccess, updateOrderOrderItemFailure} from '../features/order'
import {deleteOrderOrderItemStart, deleteOrderOrderItemSuccess, deleteOrderOrderItemFailure} from '../features/order'

import {fetchOrderOrderitemListStart, fetchOrderOrderitemListSuccess, fetchOrderOrderitemListFailure} from '../features/order'
import {fetchOrderOrderitemItemStart, fetchOrderOrderitemItemSuccess, fetchOrderOrderitemItemFailure} from '../features/order'
import {createOrderOrderitemItemStart, createOrderOrderitemItemSuccess, createOrderOrderitemItemFailure} from '../features/order'
import {updateOrderOrderitemItemStart, updateOrderOrderitemItemSuccess, updateOrderOrderitemItemFailure} from '../features/order'
import {deleteOrderOrderitemItemStart, deleteOrderOrderitemItemSuccess, deleteOrderOrderitemItemFailure} from '../features/order'


// ORDER ACTIONS
export const fetchOrderOrderList = async (dispatch, info, key) => {
    try{
        dispatch(fetchOrderOrderListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/order/order/`, { params: info.params, headers: info.headers });
        dispatch(fetchOrderOrderListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchOrderOrderListFailure({key, error: error.message}));
    }
}
export const fetchOrderOrderItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchOrderOrderItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/order/order/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchOrderOrderItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchOrderOrderItemFailure({key, error: error.message}));
    }
}
export const createOrderOrderItem = async (dispatch, info, key) => {
    try{
        dispatch(createOrderOrderItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/order/order/`, info.payload, { headers: info.headers });
        dispatch(createOrderOrderItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createOrderOrderItemFailure({key, error: error.message}));
    }
}
export const updateOrderOrderItem = async (dispatch, info, key) => {
    try{
        dispatch(updateOrderOrderItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/order/order/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateOrderOrderItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateOrderOrderItemFailure({key, error: error.message}));
    }
}
export const deleteOrderOrderItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteOrderOrderItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/order/order/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteOrderOrderItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteOrderOrderItemFailure({key, error: error.message}));
    }
}


// ORDERITEM ACTIONS
export const fetchOrderOrderitemList = async (dispatch, info, key) => {
    try{
        dispatch(fetchOrderOrderitemListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/order/orderitem/`, { params: info.params, headers: info.headers });
        dispatch(fetchOrderOrderitemListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchOrderOrderitemListFailure({key, error: error.message}));
    }
}
export const fetchOrderOrderitemItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchOrderOrderitemItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/order/orderitem/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchOrderOrderitemItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchOrderOrderitemItemFailure({key, error: error.message}));
    }
}
export const createOrderOrderitemItem = async (dispatch, info, key) => {
    try{
        dispatch(createOrderOrderitemItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/order/orderitem/`, info.payload, { headers: info.headers });
        dispatch(createOrderOrderitemItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createOrderOrderitemItemFailure({key, error: error.message}));
    }
}
export const updateOrderOrderitemItem = async (dispatch, info, key) => {
    try{
        dispatch(updateOrderOrderitemItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/order/orderitem/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateOrderOrderitemItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateOrderOrderitemItemFailure({key, error: error.message}));
    }
}
export const deleteOrderOrderitemItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteOrderOrderitemItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/order/orderitem/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteOrderOrderitemItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteOrderOrderitemItemFailure({key, error: error.message}));
    }
}




