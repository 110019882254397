import axios from "./axios";

export const getUserElement = (item_attrib) => {
  if (typeof localStorage !== "undefined") {
    const userData = localStorage.getItem("tk");
    if (userData) {
      const userInfo = JSON.parse(userData);
      return userInfo[item_attrib];
    }
  }
  return undefined;
};

export const setUserElement = (item_attrib, value) => {
  if (typeof localStorage !== "undefined") {
    const userData = localStorage.getItem("tk");
    if (userData) {
      const userInfo = JSON.parse(userData);
      userInfo[item_attrib] = value;
      localStorage.setItem("tk", JSON.stringify(userInfo));
    }
  }
  return undefined;
};

export const clearUserData = () => {
  if (typeof localStorage !== "undefined") {
    localStorage.removeItem("tk");
    localStorage.removeItem("user_id");
    localStorage.removeItem("profile_type");
    window.location.href = "/";
  }
  return undefined;
};

let runningRequests = {};
const postData = (path, params, headers) => {
  const requestKey =
    path + JSON.stringify(params || {}) + JSON.stringify(headers || {});
  const status = { no_server: false };
  if (!runningRequests[requestKey]) {
    if (headers) {
      runningRequests[requestKey] = axios(localStorage.getItem("FABU")).post(
        path,
        params,
        headers
      );
    } else {
      runningRequests[requestKey] = axios(localStorage.getItem("FABU")).post(
        path,
        params
      );
    }
  }
  return runningRequests[requestKey]
    .then((res) => {
      delete runningRequests[requestKey];
      return { status, data: res.data };
    })
    .catch((err) => {
      status.no_server = true;
      delete runningRequests[requestKey];
      return { status, data: err };
    });
};

export const getAccessToken = () => {
  const token = getUserElement("access");
  if (token) {
    return token;
  }
  return undefined;
};

export const getRefreshToken = () => {
  const token = getUserElement("refresh");
  if (token) {
    return token;
  }
  return undefined;
};

export const setAccessToken = (token) => {
  setUserElement("access", token);
};

export async function verifyToken() {
  const data = { token: getAccessToken() };
  return postData(`/user-token/verify/`, data);
}

export async function getNewAccessToken() {
  const data = { refresh: getRefreshToken() };
  return postData(`/user-token/refresh/`, data);
}

export async function getRecentAccessToken() {
  const verify_token = await verifyToken();
  if (verify_token?.status?.no_server === true) {
    const new_token = await getNewAccessToken();
    if (new_token?.data?.access) {
      setAccessToken(new_token.data.access);
      return new_token.data.access;
    }
    clearUserData();
    if (typeof window !== "undefined") {
      // window.location.href = "/";
    }
  }
  return getAccessToken();
}
