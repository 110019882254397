import { createSlice } from '@reduxjs/toolkit';

const stateLoadingStart = (state, action) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: true, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: null };
}
const stateError = (state, action) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: false, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: action.payload.error };
}
const stateSuccess = (state, action, key) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: false, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: null, [key]: action.payload.data };
}

export const slice = createSlice({
  name: 'notification',
  initialState: { loading: false },
  reducers: {
    
    // NOTIFICATION REDUCER FUNCTION

    // NOTIFICATION FETCH LIST
    fetchNotificationNotificationListStart: stateLoadingStart,
    fetchNotificationNotificationListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `notification_list`),
    fetchNotificationNotificationListFailure: stateError,

    // NOTIFICATION FETCH ITEM
    fetchNotificationNotificationItemStart: stateLoadingStart,
    fetchNotificationNotificationItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `notification_item`),
    fetchNotificationNotificationItemFailure: stateError,

    // NOTIFICATION CREATE ITEM
    createNotificationNotificationItemStart: stateLoadingStart,
    createNotificationNotificationItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `notification_item`),
    createNotificationNotificationItemFailure: stateError,

    // NOTIFICATION UPDATE ITEM
    updateNotificationNotificationItemStart: stateLoadingStart,
    updateNotificationNotificationItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `notification_item`),
    updateNotificationNotificationItemFailure: stateError,

    // NOTIFICATION DELETE ITEM
    deleteNotificationNotificationItemStart: stateLoadingStart,
    deleteNotificationNotificationItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_notification`),
    deleteNotificationNotificationItemFailure: stateError,


    // NOTIFICATIONTYPE REDUCER FUNCTION

    // NOTIFICATIONTYPE FETCH LIST
    fetchNotificationNotificationtypeListStart: stateLoadingStart,
    fetchNotificationNotificationtypeListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `notificationtype_list`),
    fetchNotificationNotificationtypeListFailure: stateError,

    // NOTIFICATIONTYPE FETCH ITEM
    fetchNotificationNotificationtypeItemStart: stateLoadingStart,
    fetchNotificationNotificationtypeItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `notificationtype_item`),
    fetchNotificationNotificationtypeItemFailure: stateError,

    // NOTIFICATIONTYPE CREATE ITEM
    createNotificationNotificationtypeItemStart: stateLoadingStart,
    createNotificationNotificationtypeItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `notificationtype_item`),
    createNotificationNotificationtypeItemFailure: stateError,

    // NOTIFICATIONTYPE UPDATE ITEM
    updateNotificationNotificationtypeItemStart: stateLoadingStart,
    updateNotificationNotificationtypeItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `notificationtype_item`),
    updateNotificationNotificationtypeItemFailure: stateError,

    // NOTIFICATIONTYPE DELETE ITEM
    deleteNotificationNotificationtypeItemStart: stateLoadingStart,
    deleteNotificationNotificationtypeItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_notificationtype`),
    deleteNotificationNotificationtypeItemFailure: stateError,


    // USERNOTIFICATIONSETTINGS REDUCER FUNCTION

    // USERNOTIFICATIONSETTINGS FETCH LIST
    fetchNotificationUsernotificationsettingsListStart: stateLoadingStart,
    fetchNotificationUsernotificationsettingsListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `usernotificationsettings_list`),
    fetchNotificationUsernotificationsettingsListFailure: stateError,

    // USERNOTIFICATIONSETTINGS FETCH ITEM
    fetchNotificationUsernotificationsettingsItemStart: stateLoadingStart,
    fetchNotificationUsernotificationsettingsItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `usernotificationsettings_item`),
    fetchNotificationUsernotificationsettingsItemFailure: stateError,

    // USERNOTIFICATIONSETTINGS CREATE ITEM
    createNotificationUsernotificationsettingsItemStart: stateLoadingStart,
    createNotificationUsernotificationsettingsItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `usernotificationsettings_item`),
    createNotificationUsernotificationsettingsItemFailure: stateError,

    // USERNOTIFICATIONSETTINGS UPDATE ITEM
    updateNotificationUsernotificationsettingsItemStart: stateLoadingStart,
    updateNotificationUsernotificationsettingsItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `usernotificationsettings_item`),
    updateNotificationUsernotificationsettingsItemFailure: stateError,

    // USERNOTIFICATIONSETTINGS DELETE ITEM
    deleteNotificationUsernotificationsettingsItemStart: stateLoadingStart,
    deleteNotificationUsernotificationsettingsItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_usernotificationsettings`),
    deleteNotificationUsernotificationsettingsItemFailure: stateError,


  },
});

export const {
  fetchNotificationNotificationListStart, fetchNotificationNotificationtypeListStart, fetchNotificationUsernotificationsettingsListStart,
  fetchNotificationNotificationListSuccess, fetchNotificationNotificationtypeListSuccess, fetchNotificationUsernotificationsettingsListSuccess,
  fetchNotificationNotificationListFailure, fetchNotificationNotificationtypeListFailure, fetchNotificationUsernotificationsettingsListFailure,
  fetchNotificationNotificationItemStart, fetchNotificationNotificationtypeItemStart, fetchNotificationUsernotificationsettingsItemStart,
  fetchNotificationNotificationItemSuccess, fetchNotificationNotificationtypeItemSuccess, fetchNotificationUsernotificationsettingsItemSuccess,
  fetchNotificationNotificationItemFailure, fetchNotificationNotificationtypeItemFailure, fetchNotificationUsernotificationsettingsItemFailure,
  createNotificationNotificationItemStart, createNotificationNotificationtypeItemStart, createNotificationUsernotificationsettingsItemStart,
  createNotificationNotificationItemSuccess, createNotificationNotificationtypeItemSuccess, createNotificationUsernotificationsettingsItemSuccess,
  createNotificationNotificationItemFailure, createNotificationNotificationtypeItemFailure, createNotificationUsernotificationsettingsItemFailure,
  updateNotificationNotificationItemStart, updateNotificationNotificationtypeItemStart, updateNotificationUsernotificationsettingsItemStart,
  updateNotificationNotificationItemSuccess, updateNotificationNotificationtypeItemSuccess, updateNotificationUsernotificationsettingsItemSuccess,
  updateNotificationNotificationItemFailure, updateNotificationNotificationtypeItemFailure, updateNotificationUsernotificationsettingsItemFailure,
  deleteNotificationNotificationItemStart, deleteNotificationNotificationtypeItemStart, deleteNotificationUsernotificationsettingsItemStart,
  deleteNotificationNotificationItemSuccess, deleteNotificationNotificationtypeItemSuccess, deleteNotificationUsernotificationsettingsItemSuccess,
  deleteNotificationNotificationItemFailure, deleteNotificationNotificationtypeItemFailure, deleteNotificationUsernotificationsettingsItemFailure
} = slice.actions;

export default slice.reducer;
