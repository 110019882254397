import { createSlice } from '@reduxjs/toolkit';

const stateLoadingStart = (state, action) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: true, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: null };
}
const stateError = (state, action) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: false, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: action.payload.error };
}
const stateSuccess = (state, action, key) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: false, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: null, [key]: action.payload.data };
}

export const slice = createSlice({
  name: 'customer',
  initialState: { loading: false },
  reducers: {
    
    // WHOLESALER REDUCER FUNCTION

    // WHOLESALER FETCH LIST
    fetchCustomerWholesalerListStart: stateLoadingStart,
    fetchCustomerWholesalerListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `wholesaler_list`),
    fetchCustomerWholesalerListFailure: stateError,

    // WHOLESALER FETCH ITEM
    fetchCustomerWholesalerItemStart: stateLoadingStart,
    fetchCustomerWholesalerItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `wholesaler_item`),
    fetchCustomerWholesalerItemFailure: stateError,

    // WHOLESALER CREATE ITEM
    createCustomerWholesalerItemStart: stateLoadingStart,
    createCustomerWholesalerItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `wholesaler_item`),
    createCustomerWholesalerItemFailure: stateError,

    // WHOLESALER UPDATE ITEM
    updateCustomerWholesalerItemStart: stateLoadingStart,
    updateCustomerWholesalerItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `wholesaler_item`),
    updateCustomerWholesalerItemFailure: stateError,

    // WHOLESALER DELETE ITEM
    deleteCustomerWholesalerItemStart: stateLoadingStart,
    deleteCustomerWholesalerItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_wholesaler`),
    deleteCustomerWholesalerItemFailure: stateError,


    // RETAILER REDUCER FUNCTION

    // RETAILER FETCH LIST
    fetchCustomerRetailerListStart: stateLoadingStart,
    fetchCustomerRetailerListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `retailer_list`),
    fetchCustomerRetailerListFailure: stateError,

    // RETAILER FETCH ITEM
    fetchCustomerRetailerItemStart: stateLoadingStart,
    fetchCustomerRetailerItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `retailer_item`),
    fetchCustomerRetailerItemFailure: stateError,

    // RETAILER CREATE ITEM
    createCustomerRetailerItemStart: stateLoadingStart,
    createCustomerRetailerItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `retailer_item`),
    createCustomerRetailerItemFailure: stateError,

    // RETAILER UPDATE ITEM
    updateCustomerRetailerItemStart: stateLoadingStart,
    updateCustomerRetailerItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `retailer_item`),
    updateCustomerRetailerItemFailure: stateError,

    // RETAILER DELETE ITEM
    deleteCustomerRetailerItemStart: stateLoadingStart,
    deleteCustomerRetailerItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_retailer`),
    deleteCustomerRetailerItemFailure: stateError,


    // INDIVIDUAL REDUCER FUNCTION

    // INDIVIDUAL FETCH LIST
    fetchCustomerIndividualListStart: stateLoadingStart,
    fetchCustomerIndividualListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `individual_list`),
    fetchCustomerIndividualListFailure: stateError,

    // INDIVIDUAL FETCH ITEM
    fetchCustomerIndividualItemStart: stateLoadingStart,
    fetchCustomerIndividualItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `individual_item`),
    fetchCustomerIndividualItemFailure: stateError,

    // INDIVIDUAL CREATE ITEM
    createCustomerIndividualItemStart: stateLoadingStart,
    createCustomerIndividualItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `individual_item`),
    createCustomerIndividualItemFailure: stateError,

    // INDIVIDUAL UPDATE ITEM
    updateCustomerIndividualItemStart: stateLoadingStart,
    updateCustomerIndividualItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `individual_item`),
    updateCustomerIndividualItemFailure: stateError,

    // INDIVIDUAL DELETE ITEM
    deleteCustomerIndividualItemStart: stateLoadingStart,
    deleteCustomerIndividualItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_individual`),
    deleteCustomerIndividualItemFailure: stateError,


  },
});

export const {
  fetchCustomerWholesalerListStart, fetchCustomerRetailerListStart, fetchCustomerIndividualListStart,
  fetchCustomerWholesalerListSuccess, fetchCustomerRetailerListSuccess, fetchCustomerIndividualListSuccess,
  fetchCustomerWholesalerListFailure, fetchCustomerRetailerListFailure, fetchCustomerIndividualListFailure,
  fetchCustomerWholesalerItemStart, fetchCustomerRetailerItemStart, fetchCustomerIndividualItemStart,
  fetchCustomerWholesalerItemSuccess, fetchCustomerRetailerItemSuccess, fetchCustomerIndividualItemSuccess,
  fetchCustomerWholesalerItemFailure, fetchCustomerRetailerItemFailure, fetchCustomerIndividualItemFailure,
  createCustomerWholesalerItemStart, createCustomerRetailerItemStart, createCustomerIndividualItemStart,
  createCustomerWholesalerItemSuccess, createCustomerRetailerItemSuccess, createCustomerIndividualItemSuccess,
  createCustomerWholesalerItemFailure, createCustomerRetailerItemFailure, createCustomerIndividualItemFailure,
  updateCustomerWholesalerItemStart, updateCustomerRetailerItemStart, updateCustomerIndividualItemStart,
  updateCustomerWholesalerItemSuccess, updateCustomerRetailerItemSuccess, updateCustomerIndividualItemSuccess,
  updateCustomerWholesalerItemFailure, updateCustomerRetailerItemFailure, updateCustomerIndividualItemFailure,
  deleteCustomerWholesalerItemStart, deleteCustomerRetailerItemStart, deleteCustomerIndividualItemStart,
  deleteCustomerWholesalerItemSuccess, deleteCustomerRetailerItemSuccess, deleteCustomerIndividualItemSuccess,
  deleteCustomerWholesalerItemFailure, deleteCustomerRetailerItemFailure, deleteCustomerIndividualItemFailure
} = slice.actions;

export default slice.reducer;
