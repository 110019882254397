import { createSlice } from '@reduxjs/toolkit';

const stateLoadingStart = (state, action) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: true, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: null };
}
const stateError = (state, action) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: false, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: action.payload.error };
}
const stateSuccess = (state, action, key) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: false, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: null, [key]: action.payload.data };
}

export const slice = createSlice({
  name: 'delivery',
  initialState: { loading: false },
  reducers: {
    
    // DELIVERYCOMPANY REDUCER FUNCTION

    // DELIVERYCOMPANY FETCH LIST
    fetchDeliveryDeliverycompanyListStart: stateLoadingStart,
    fetchDeliveryDeliverycompanyListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deliverycompany_list`),
    fetchDeliveryDeliverycompanyListFailure: stateError,

    // DELIVERYCOMPANY FETCH ITEM
    fetchDeliveryDeliverycompanyItemStart: stateLoadingStart,
    fetchDeliveryDeliverycompanyItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deliverycompany_item`),
    fetchDeliveryDeliverycompanyItemFailure: stateError,

    // DELIVERYCOMPANY CREATE ITEM
    createDeliveryDeliverycompanyItemStart: stateLoadingStart,
    createDeliveryDeliverycompanyItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deliverycompany_item`),
    createDeliveryDeliverycompanyItemFailure: stateError,

    // DELIVERYCOMPANY UPDATE ITEM
    updateDeliveryDeliverycompanyItemStart: stateLoadingStart,
    updateDeliveryDeliverycompanyItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deliverycompany_item`),
    updateDeliveryDeliverycompanyItemFailure: stateError,

    // DELIVERYCOMPANY DELETE ITEM
    deleteDeliveryDeliverycompanyItemStart: stateLoadingStart,
    deleteDeliveryDeliverycompanyItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_deliverycompany`),
    deleteDeliveryDeliverycompanyItemFailure: stateError,


    // DELIVERYPERSON REDUCER FUNCTION

    // DELIVERYPERSON FETCH LIST
    fetchDeliveryDeliverypersonListStart: stateLoadingStart,
    fetchDeliveryDeliverypersonListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deliveryperson_list`),
    fetchDeliveryDeliverypersonListFailure: stateError,

    // DELIVERYPERSON FETCH ITEM
    fetchDeliveryDeliverypersonItemStart: stateLoadingStart,
    fetchDeliveryDeliverypersonItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deliveryperson_item`),
    fetchDeliveryDeliverypersonItemFailure: stateError,

    // DELIVERYPERSON CREATE ITEM
    createDeliveryDeliverypersonItemStart: stateLoadingStart,
    createDeliveryDeliverypersonItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deliveryperson_item`),
    createDeliveryDeliverypersonItemFailure: stateError,

    // DELIVERYPERSON UPDATE ITEM
    updateDeliveryDeliverypersonItemStart: stateLoadingStart,
    updateDeliveryDeliverypersonItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deliveryperson_item`),
    updateDeliveryDeliverypersonItemFailure: stateError,

    // DELIVERYPERSON DELETE ITEM
    deleteDeliveryDeliverypersonItemStart: stateLoadingStart,
    deleteDeliveryDeliverypersonItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_deliveryperson`),
    deleteDeliveryDeliverypersonItemFailure: stateError,


    // DELIVERYORDER REDUCER FUNCTION

    // DELIVERYORDER FETCH LIST
    fetchDeliveryDeliveryorderListStart: stateLoadingStart,
    fetchDeliveryDeliveryorderListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deliveryorder_list`),
    fetchDeliveryDeliveryorderListFailure: stateError,

    // DELIVERYORDER FETCH ITEM
    fetchDeliveryDeliveryorderItemStart: stateLoadingStart,
    fetchDeliveryDeliveryorderItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deliveryorder_item`),
    fetchDeliveryDeliveryorderItemFailure: stateError,

    // DELIVERYORDER CREATE ITEM
    createDeliveryDeliveryorderItemStart: stateLoadingStart,
    createDeliveryDeliveryorderItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deliveryorder_item`),
    createDeliveryDeliveryorderItemFailure: stateError,

    // DELIVERYORDER UPDATE ITEM
    updateDeliveryDeliveryorderItemStart: stateLoadingStart,
    updateDeliveryDeliveryorderItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deliveryorder_item`),
    updateDeliveryDeliveryorderItemFailure: stateError,

    // DELIVERYORDER DELETE ITEM
    deleteDeliveryDeliveryorderItemStart: stateLoadingStart,
    deleteDeliveryDeliveryorderItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_deliveryorder`),
    deleteDeliveryDeliveryorderItemFailure: stateError,


    // DELIVERYTRACKING REDUCER FUNCTION

    // DELIVERYTRACKING FETCH LIST
    fetchDeliveryDeliverytrackingListStart: stateLoadingStart,
    fetchDeliveryDeliverytrackingListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deliverytracking_list`),
    fetchDeliveryDeliverytrackingListFailure: stateError,

    // DELIVERYTRACKING FETCH ITEM
    fetchDeliveryDeliverytrackingItemStart: stateLoadingStart,
    fetchDeliveryDeliverytrackingItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deliverytracking_item`),
    fetchDeliveryDeliverytrackingItemFailure: stateError,

    // DELIVERYTRACKING CREATE ITEM
    createDeliveryDeliverytrackingItemStart: stateLoadingStart,
    createDeliveryDeliverytrackingItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deliverytracking_item`),
    createDeliveryDeliverytrackingItemFailure: stateError,

    // DELIVERYTRACKING UPDATE ITEM
    updateDeliveryDeliverytrackingItemStart: stateLoadingStart,
    updateDeliveryDeliverytrackingItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deliverytracking_item`),
    updateDeliveryDeliverytrackingItemFailure: stateError,

    // DELIVERYTRACKING DELETE ITEM
    deleteDeliveryDeliverytrackingItemStart: stateLoadingStart,
    deleteDeliveryDeliverytrackingItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_deliverytracking`),
    deleteDeliveryDeliverytrackingItemFailure: stateError,


  },
});

export const {
  fetchDeliveryDeliverycompanyListStart, fetchDeliveryDeliverypersonListStart, fetchDeliveryDeliveryorderListStart, fetchDeliveryDeliverytrackingListStart,
  fetchDeliveryDeliverycompanyListSuccess, fetchDeliveryDeliverypersonListSuccess, fetchDeliveryDeliveryorderListSuccess, fetchDeliveryDeliverytrackingListSuccess,
  fetchDeliveryDeliverycompanyListFailure, fetchDeliveryDeliverypersonListFailure, fetchDeliveryDeliveryorderListFailure, fetchDeliveryDeliverytrackingListFailure,
  fetchDeliveryDeliverycompanyItemStart, fetchDeliveryDeliverypersonItemStart, fetchDeliveryDeliveryorderItemStart, fetchDeliveryDeliverytrackingItemStart,
  fetchDeliveryDeliverycompanyItemSuccess, fetchDeliveryDeliverypersonItemSuccess, fetchDeliveryDeliveryorderItemSuccess, fetchDeliveryDeliverytrackingItemSuccess,
  fetchDeliveryDeliverycompanyItemFailure, fetchDeliveryDeliverypersonItemFailure, fetchDeliveryDeliveryorderItemFailure, fetchDeliveryDeliverytrackingItemFailure,
  createDeliveryDeliverycompanyItemStart, createDeliveryDeliverypersonItemStart, createDeliveryDeliveryorderItemStart, createDeliveryDeliverytrackingItemStart,
  createDeliveryDeliverycompanyItemSuccess, createDeliveryDeliverypersonItemSuccess, createDeliveryDeliveryorderItemSuccess, createDeliveryDeliverytrackingItemSuccess,
  createDeliveryDeliverycompanyItemFailure, createDeliveryDeliverypersonItemFailure, createDeliveryDeliveryorderItemFailure, createDeliveryDeliverytrackingItemFailure,
  updateDeliveryDeliverycompanyItemStart, updateDeliveryDeliverypersonItemStart, updateDeliveryDeliveryorderItemStart, updateDeliveryDeliverytrackingItemStart,
  updateDeliveryDeliverycompanyItemSuccess, updateDeliveryDeliverypersonItemSuccess, updateDeliveryDeliveryorderItemSuccess, updateDeliveryDeliverytrackingItemSuccess,
  updateDeliveryDeliverycompanyItemFailure, updateDeliveryDeliverypersonItemFailure, updateDeliveryDeliveryorderItemFailure, updateDeliveryDeliverytrackingItemFailure,
  deleteDeliveryDeliverycompanyItemStart, deleteDeliveryDeliverypersonItemStart, deleteDeliveryDeliveryorderItemStart, deleteDeliveryDeliverytrackingItemStart,
  deleteDeliveryDeliverycompanyItemSuccess, deleteDeliveryDeliverypersonItemSuccess, deleteDeliveryDeliveryorderItemSuccess, deleteDeliveryDeliverytrackingItemSuccess,
  deleteDeliveryDeliverycompanyItemFailure, deleteDeliveryDeliverypersonItemFailure, deleteDeliveryDeliveryorderItemFailure, deleteDeliveryDeliverytrackingItemFailure
} = slice.actions;

export default slice.reducer;
