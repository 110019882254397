import axios from '../utils/axios';

import {fetchPricingTaxListStart, fetchPricingTaxListSuccess, fetchPricingTaxListFailure} from '../features/pricing'
import {fetchPricingTaxItemStart, fetchPricingTaxItemSuccess, fetchPricingTaxItemFailure} from '../features/pricing'
import {createPricingTaxItemStart, createPricingTaxItemSuccess, createPricingTaxItemFailure} from '../features/pricing'
import {updatePricingTaxItemStart, updatePricingTaxItemSuccess, updatePricingTaxItemFailure} from '../features/pricing'
import {deletePricingTaxItemStart, deletePricingTaxItemSuccess, deletePricingTaxItemFailure} from '../features/pricing'

import {fetchPricingDiscountListStart, fetchPricingDiscountListSuccess, fetchPricingDiscountListFailure} from '../features/pricing'
import {fetchPricingDiscountItemStart, fetchPricingDiscountItemSuccess, fetchPricingDiscountItemFailure} from '../features/pricing'
import {createPricingDiscountItemStart, createPricingDiscountItemSuccess, createPricingDiscountItemFailure} from '../features/pricing'
import {updatePricingDiscountItemStart, updatePricingDiscountItemSuccess, updatePricingDiscountItemFailure} from '../features/pricing'
import {deletePricingDiscountItemStart, deletePricingDiscountItemSuccess, deletePricingDiscountItemFailure} from '../features/pricing'

import {fetchPricingCouponListStart, fetchPricingCouponListSuccess, fetchPricingCouponListFailure} from '../features/pricing'
import {fetchPricingCouponItemStart, fetchPricingCouponItemSuccess, fetchPricingCouponItemFailure} from '../features/pricing'
import {createPricingCouponItemStart, createPricingCouponItemSuccess, createPricingCouponItemFailure} from '../features/pricing'
import {updatePricingCouponItemStart, updatePricingCouponItemSuccess, updatePricingCouponItemFailure} from '../features/pricing'
import {deletePricingCouponItemStart, deletePricingCouponItemSuccess, deletePricingCouponItemFailure} from '../features/pricing'


// TAX ACTIONS
export const fetchPricingTaxList = async (dispatch, info, key) => {
    try{
        dispatch(fetchPricingTaxListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/pricing/tax/`, { params: info.params, headers: info.headers });
        dispatch(fetchPricingTaxListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchPricingTaxListFailure({key, error: error.message}));
    }
}
export const fetchPricingTaxItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchPricingTaxItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/pricing/tax/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchPricingTaxItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchPricingTaxItemFailure({key, error: error.message}));
    }
}
export const createPricingTaxItem = async (dispatch, info, key) => {
    try{
        dispatch(createPricingTaxItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/pricing/tax/`, info.payload, { headers: info.headers });
        dispatch(createPricingTaxItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createPricingTaxItemFailure({key, error: error.message}));
    }
}
export const updatePricingTaxItem = async (dispatch, info, key) => {
    try{
        dispatch(updatePricingTaxItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/pricing/tax/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updatePricingTaxItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updatePricingTaxItemFailure({key, error: error.message}));
    }
}
export const deletePricingTaxItem = async (dispatch, info, key) => {
    try{
        dispatch(deletePricingTaxItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/pricing/tax/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deletePricingTaxItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deletePricingTaxItemFailure({key, error: error.message}));
    }
}


// DISCOUNT ACTIONS
export const fetchPricingDiscountList = async (dispatch, info, key) => {
    try{
        dispatch(fetchPricingDiscountListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/pricing/discount/`, { params: info.params, headers: info.headers });
        dispatch(fetchPricingDiscountListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchPricingDiscountListFailure({key, error: error.message}));
    }
}
export const fetchPricingDiscountItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchPricingDiscountItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/pricing/discount/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchPricingDiscountItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchPricingDiscountItemFailure({key, error: error.message}));
    }
}
export const createPricingDiscountItem = async (dispatch, info, key) => {
    try{
        dispatch(createPricingDiscountItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/pricing/discount/`, info.payload, { headers: info.headers });
        dispatch(createPricingDiscountItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createPricingDiscountItemFailure({key, error: error.message}));
    }
}
export const updatePricingDiscountItem = async (dispatch, info, key) => {
    try{
        dispatch(updatePricingDiscountItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/pricing/discount/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updatePricingDiscountItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updatePricingDiscountItemFailure({key, error: error.message}));
    }
}
export const deletePricingDiscountItem = async (dispatch, info, key) => {
    try{
        dispatch(deletePricingDiscountItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/pricing/discount/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deletePricingDiscountItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deletePricingDiscountItemFailure({key, error: error.message}));
    }
}


// COUPON ACTIONS
export const fetchPricingCouponList = async (dispatch, info, key) => {
    try{
        dispatch(fetchPricingCouponListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/pricing/coupon/`, { params: info.params, headers: info.headers });
        dispatch(fetchPricingCouponListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchPricingCouponListFailure({key, error: error.message}));
    }
}
export const fetchPricingCouponItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchPricingCouponItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/pricing/coupon/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchPricingCouponItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchPricingCouponItemFailure({key, error: error.message}));
    }
}
export const createPricingCouponItem = async (dispatch, info, key) => {
    try{
        dispatch(createPricingCouponItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/pricing/coupon/`, info.payload, { headers: info.headers });
        dispatch(createPricingCouponItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createPricingCouponItemFailure({key, error: error.message}));
    }
}
export const updatePricingCouponItem = async (dispatch, info, key) => {
    try{
        dispatch(updatePricingCouponItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/pricing/coupon/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updatePricingCouponItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updatePricingCouponItemFailure({key, error: error.message}));
    }
}
export const deletePricingCouponItem = async (dispatch, info, key) => {
    try{
        dispatch(deletePricingCouponItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/pricing/coupon/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deletePricingCouponItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deletePricingCouponItemFailure({key, error: error.message}));
    }
}




