import axios from '../utils/axios';

import {fetchCustomerWholesalerListStart, fetchCustomerWholesalerListSuccess, fetchCustomerWholesalerListFailure} from '../features/customer'
import {fetchCustomerWholesalerItemStart, fetchCustomerWholesalerItemSuccess, fetchCustomerWholesalerItemFailure} from '../features/customer'
import {createCustomerWholesalerItemStart, createCustomerWholesalerItemSuccess, createCustomerWholesalerItemFailure} from '../features/customer'
import {updateCustomerWholesalerItemStart, updateCustomerWholesalerItemSuccess, updateCustomerWholesalerItemFailure} from '../features/customer'
import {deleteCustomerWholesalerItemStart, deleteCustomerWholesalerItemSuccess, deleteCustomerWholesalerItemFailure} from '../features/customer'

import {fetchCustomerRetailerListStart, fetchCustomerRetailerListSuccess, fetchCustomerRetailerListFailure} from '../features/customer'
import {fetchCustomerRetailerItemStart, fetchCustomerRetailerItemSuccess, fetchCustomerRetailerItemFailure} from '../features/customer'
import {createCustomerRetailerItemStart, createCustomerRetailerItemSuccess, createCustomerRetailerItemFailure} from '../features/customer'
import {updateCustomerRetailerItemStart, updateCustomerRetailerItemSuccess, updateCustomerRetailerItemFailure} from '../features/customer'
import {deleteCustomerRetailerItemStart, deleteCustomerRetailerItemSuccess, deleteCustomerRetailerItemFailure} from '../features/customer'

import {fetchCustomerIndividualListStart, fetchCustomerIndividualListSuccess, fetchCustomerIndividualListFailure} from '../features/customer'
import {fetchCustomerIndividualItemStart, fetchCustomerIndividualItemSuccess, fetchCustomerIndividualItemFailure} from '../features/customer'
import {createCustomerIndividualItemStart, createCustomerIndividualItemSuccess, createCustomerIndividualItemFailure} from '../features/customer'
import {updateCustomerIndividualItemStart, updateCustomerIndividualItemSuccess, updateCustomerIndividualItemFailure} from '../features/customer'
import {deleteCustomerIndividualItemStart, deleteCustomerIndividualItemSuccess, deleteCustomerIndividualItemFailure} from '../features/customer'


// WHOLESALER ACTIONS
export const fetchCustomerWholesalerList = async (dispatch, info, key) => {
    try{
        dispatch(fetchCustomerWholesalerListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/customer/wholesaler/`, { params: info.params, headers: info.headers });
        dispatch(fetchCustomerWholesalerListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchCustomerWholesalerListFailure({key, error: error.message}));
    }
}
export const fetchCustomerWholesalerItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchCustomerWholesalerItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/customer/wholesaler/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchCustomerWholesalerItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchCustomerWholesalerItemFailure({key, error: error.message}));
    }
}
export const createCustomerWholesalerItem = async (dispatch, info, key) => {
    try{
        dispatch(createCustomerWholesalerItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/customer/wholesaler/`, info.payload, { headers: info.headers });
        dispatch(createCustomerWholesalerItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createCustomerWholesalerItemFailure({key, error: error.message}));
    }
}
export const updateCustomerWholesalerItem = async (dispatch, info, key) => {
    try{
        dispatch(updateCustomerWholesalerItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/customer/wholesaler/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateCustomerWholesalerItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateCustomerWholesalerItemFailure({key, error: error.message}));
    }
}
export const deleteCustomerWholesalerItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteCustomerWholesalerItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/customer/wholesaler/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteCustomerWholesalerItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteCustomerWholesalerItemFailure({key, error: error.message}));
    }
}


// RETAILER ACTIONS
export const fetchCustomerRetailerList = async (dispatch, info, key) => {
    try{
        dispatch(fetchCustomerRetailerListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/customer/retailer/`, { params: info.params, headers: info.headers });
        dispatch(fetchCustomerRetailerListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchCustomerRetailerListFailure({key, error: error.message}));
    }
}
export const fetchCustomerRetailerItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchCustomerRetailerItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/customer/retailer/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchCustomerRetailerItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchCustomerRetailerItemFailure({key, error: error.message}));
    }
}
export const createCustomerRetailerItem = async (dispatch, info, key) => {
    try{
        dispatch(createCustomerRetailerItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/customer/retailer/`, info.payload, { headers: info.headers });
        dispatch(createCustomerRetailerItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createCustomerRetailerItemFailure({key, error: error.message}));
    }
}
export const updateCustomerRetailerItem = async (dispatch, info, key) => {
    try{
        dispatch(updateCustomerRetailerItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/customer/retailer/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateCustomerRetailerItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateCustomerRetailerItemFailure({key, error: error.message}));
    }
}
export const deleteCustomerRetailerItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteCustomerRetailerItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/customer/retailer/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteCustomerRetailerItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteCustomerRetailerItemFailure({key, error: error.message}));
    }
}


// INDIVIDUAL ACTIONS
export const fetchCustomerIndividualList = async (dispatch, info, key) => {
    try{
        dispatch(fetchCustomerIndividualListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/customer/individual/`, { params: info.params, headers: info.headers });
        dispatch(fetchCustomerIndividualListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchCustomerIndividualListFailure({key, error: error.message}));
    }
}
export const fetchCustomerIndividualItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchCustomerIndividualItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/customer/individual/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchCustomerIndividualItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchCustomerIndividualItemFailure({key, error: error.message}));
    }
}
export const createCustomerIndividualItem = async (dispatch, info, key) => {
    try{
        dispatch(createCustomerIndividualItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/customer/individual/`, info.payload, { headers: info.headers });
        dispatch(createCustomerIndividualItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createCustomerIndividualItemFailure({key, error: error.message}));
    }
}
export const updateCustomerIndividualItem = async (dispatch, info, key) => {
    try{
        dispatch(updateCustomerIndividualItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/customer/individual/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateCustomerIndividualItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateCustomerIndividualItemFailure({key, error: error.message}));
    }
}
export const deleteCustomerIndividualItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteCustomerIndividualItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/customer/individual/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteCustomerIndividualItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteCustomerIndividualItemFailure({key, error: error.message}));
    }
}




