import axios from '../utils/axios';

import {fetchDeliveryDeliverycompanyListStart, fetchDeliveryDeliverycompanyListSuccess, fetchDeliveryDeliverycompanyListFailure} from '../features/delivery'
import {fetchDeliveryDeliverycompanyItemStart, fetchDeliveryDeliverycompanyItemSuccess, fetchDeliveryDeliverycompanyItemFailure} from '../features/delivery'
import {createDeliveryDeliverycompanyItemStart, createDeliveryDeliverycompanyItemSuccess, createDeliveryDeliverycompanyItemFailure} from '../features/delivery'
import {updateDeliveryDeliverycompanyItemStart, updateDeliveryDeliverycompanyItemSuccess, updateDeliveryDeliverycompanyItemFailure} from '../features/delivery'
import {deleteDeliveryDeliverycompanyItemStart, deleteDeliveryDeliverycompanyItemSuccess, deleteDeliveryDeliverycompanyItemFailure} from '../features/delivery'

import {fetchDeliveryDeliverypersonListStart, fetchDeliveryDeliverypersonListSuccess, fetchDeliveryDeliverypersonListFailure} from '../features/delivery'
import {fetchDeliveryDeliverypersonItemStart, fetchDeliveryDeliverypersonItemSuccess, fetchDeliveryDeliverypersonItemFailure} from '../features/delivery'
import {createDeliveryDeliverypersonItemStart, createDeliveryDeliverypersonItemSuccess, createDeliveryDeliverypersonItemFailure} from '../features/delivery'
import {updateDeliveryDeliverypersonItemStart, updateDeliveryDeliverypersonItemSuccess, updateDeliveryDeliverypersonItemFailure} from '../features/delivery'
import {deleteDeliveryDeliverypersonItemStart, deleteDeliveryDeliverypersonItemSuccess, deleteDeliveryDeliverypersonItemFailure} from '../features/delivery'

import {fetchDeliveryDeliveryorderListStart, fetchDeliveryDeliveryorderListSuccess, fetchDeliveryDeliveryorderListFailure} from '../features/delivery'
import {fetchDeliveryDeliveryorderItemStart, fetchDeliveryDeliveryorderItemSuccess, fetchDeliveryDeliveryorderItemFailure} from '../features/delivery'
import {createDeliveryDeliveryorderItemStart, createDeliveryDeliveryorderItemSuccess, createDeliveryDeliveryorderItemFailure} from '../features/delivery'
import {updateDeliveryDeliveryorderItemStart, updateDeliveryDeliveryorderItemSuccess, updateDeliveryDeliveryorderItemFailure} from '../features/delivery'
import {deleteDeliveryDeliveryorderItemStart, deleteDeliveryDeliveryorderItemSuccess, deleteDeliveryDeliveryorderItemFailure} from '../features/delivery'

import {fetchDeliveryDeliverytrackingListStart, fetchDeliveryDeliverytrackingListSuccess, fetchDeliveryDeliverytrackingListFailure} from '../features/delivery'
import {fetchDeliveryDeliverytrackingItemStart, fetchDeliveryDeliverytrackingItemSuccess, fetchDeliveryDeliverytrackingItemFailure} from '../features/delivery'
import {createDeliveryDeliverytrackingItemStart, createDeliveryDeliverytrackingItemSuccess, createDeliveryDeliverytrackingItemFailure} from '../features/delivery'
import {updateDeliveryDeliverytrackingItemStart, updateDeliveryDeliverytrackingItemSuccess, updateDeliveryDeliverytrackingItemFailure} from '../features/delivery'
import {deleteDeliveryDeliverytrackingItemStart, deleteDeliveryDeliverytrackingItemSuccess, deleteDeliveryDeliverytrackingItemFailure} from '../features/delivery'


// DELIVERYCOMPANY ACTIONS
export const fetchDeliveryDeliverycompanyList = async (dispatch, info, key) => {
    try{
        dispatch(fetchDeliveryDeliverycompanyListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/delivery/deliverycompany/`, { params: info.params, headers: info.headers });
        dispatch(fetchDeliveryDeliverycompanyListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchDeliveryDeliverycompanyListFailure({key, error: error.message}));
    }
}
export const fetchDeliveryDeliverycompanyItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchDeliveryDeliverycompanyItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/delivery/deliverycompany/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchDeliveryDeliverycompanyItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchDeliveryDeliverycompanyItemFailure({key, error: error.message}));
    }
}
export const createDeliveryDeliverycompanyItem = async (dispatch, info, key) => {
    try{
        dispatch(createDeliveryDeliverycompanyItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/delivery/deliverycompany/`, info.payload, { headers: info.headers });
        dispatch(createDeliveryDeliverycompanyItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createDeliveryDeliverycompanyItemFailure({key, error: error.message}));
    }
}
export const updateDeliveryDeliverycompanyItem = async (dispatch, info, key) => {
    try{
        dispatch(updateDeliveryDeliverycompanyItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/delivery/deliverycompany/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateDeliveryDeliverycompanyItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateDeliveryDeliverycompanyItemFailure({key, error: error.message}));
    }
}
export const deleteDeliveryDeliverycompanyItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteDeliveryDeliverycompanyItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/delivery/deliverycompany/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteDeliveryDeliverycompanyItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteDeliveryDeliverycompanyItemFailure({key, error: error.message}));
    }
}


// DELIVERYPERSON ACTIONS
export const fetchDeliveryDeliverypersonList = async (dispatch, info, key) => {
    try{
        dispatch(fetchDeliveryDeliverypersonListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/delivery/deliveryperson/`, { params: info.params, headers: info.headers });
        dispatch(fetchDeliveryDeliverypersonListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchDeliveryDeliverypersonListFailure({key, error: error.message}));
    }
}
export const fetchDeliveryDeliverypersonItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchDeliveryDeliverypersonItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/delivery/deliveryperson/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchDeliveryDeliverypersonItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchDeliveryDeliverypersonItemFailure({key, error: error.message}));
    }
}
export const createDeliveryDeliverypersonItem = async (dispatch, info, key) => {
    try{
        dispatch(createDeliveryDeliverypersonItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/delivery/deliveryperson/`, info.payload, { headers: info.headers });
        dispatch(createDeliveryDeliverypersonItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createDeliveryDeliverypersonItemFailure({key, error: error.message}));
    }
}
export const updateDeliveryDeliverypersonItem = async (dispatch, info, key) => {
    try{
        dispatch(updateDeliveryDeliverypersonItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/delivery/deliveryperson/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateDeliveryDeliverypersonItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateDeliveryDeliverypersonItemFailure({key, error: error.message}));
    }
}
export const deleteDeliveryDeliverypersonItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteDeliveryDeliverypersonItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/delivery/deliveryperson/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteDeliveryDeliverypersonItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteDeliveryDeliverypersonItemFailure({key, error: error.message}));
    }
}


// DELIVERYORDER ACTIONS
export const fetchDeliveryDeliveryorderList = async (dispatch, info, key) => {
    try{
        dispatch(fetchDeliveryDeliveryorderListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/delivery/deliveryorder/`, { params: info.params, headers: info.headers });
        dispatch(fetchDeliveryDeliveryorderListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchDeliveryDeliveryorderListFailure({key, error: error.message}));
    }
}
export const fetchDeliveryDeliveryorderItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchDeliveryDeliveryorderItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/delivery/deliveryorder/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchDeliveryDeliveryorderItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchDeliveryDeliveryorderItemFailure({key, error: error.message}));
    }
}
export const createDeliveryDeliveryorderItem = async (dispatch, info, key) => {
    try{
        dispatch(createDeliveryDeliveryorderItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/delivery/deliveryorder/`, info.payload, { headers: info.headers });
        dispatch(createDeliveryDeliveryorderItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createDeliveryDeliveryorderItemFailure({key, error: error.message}));
    }
}
export const updateDeliveryDeliveryorderItem = async (dispatch, info, key) => {
    try{
        dispatch(updateDeliveryDeliveryorderItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/delivery/deliveryorder/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateDeliveryDeliveryorderItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateDeliveryDeliveryorderItemFailure({key, error: error.message}));
    }
}
export const deleteDeliveryDeliveryorderItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteDeliveryDeliveryorderItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/delivery/deliveryorder/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteDeliveryDeliveryorderItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteDeliveryDeliveryorderItemFailure({key, error: error.message}));
    }
}


// DELIVERYTRACKING ACTIONS
export const fetchDeliveryDeliverytrackingList = async (dispatch, info, key) => {
    try{
        dispatch(fetchDeliveryDeliverytrackingListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/delivery/deliverytracking/`, { params: info.params, headers: info.headers });
        dispatch(fetchDeliveryDeliverytrackingListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchDeliveryDeliverytrackingListFailure({key, error: error.message}));
    }
}
export const fetchDeliveryDeliverytrackingItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchDeliveryDeliverytrackingItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/delivery/deliverytracking/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchDeliveryDeliverytrackingItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchDeliveryDeliverytrackingItemFailure({key, error: error.message}));
    }
}
export const createDeliveryDeliverytrackingItem = async (dispatch, info, key) => {
    try{
        dispatch(createDeliveryDeliverytrackingItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/delivery/deliverytracking/`, info.payload, { headers: info.headers });
        dispatch(createDeliveryDeliverytrackingItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createDeliveryDeliverytrackingItemFailure({key, error: error.message}));
    }
}
export const updateDeliveryDeliverytrackingItem = async (dispatch, info, key) => {
    try{
        dispatch(updateDeliveryDeliverytrackingItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/delivery/deliverytracking/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateDeliveryDeliverytrackingItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateDeliveryDeliverytrackingItemFailure({key, error: error.message}));
    }
}
export const deleteDeliveryDeliverytrackingItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteDeliveryDeliverytrackingItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/delivery/deliverytracking/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteDeliveryDeliverytrackingItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteDeliveryDeliverytrackingItemFailure({key, error: error.message}));
    }
}




