import { createSlice } from '@reduxjs/toolkit';

const stateLoadingStart = (state, action) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: true, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: null };
}
const stateError = (state, action) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: false, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: action.payload.error };
}
const stateSuccess = (state, action, key) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: false, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: null, [key]: action.payload.data };
}

export const slice = createSlice({
  name: 'packaging',
  initialState: { loading: false },
  reducers: {
    
    // PACKAGINGTYPE REDUCER FUNCTION

    // PACKAGINGTYPE FETCH LIST
    fetchPackagingPackagingtypeListStart: stateLoadingStart,
    fetchPackagingPackagingtypeListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `packagingtype_list`),
    fetchPackagingPackagingtypeListFailure: stateError,

    // PACKAGINGTYPE FETCH ITEM
    fetchPackagingPackagingtypeItemStart: stateLoadingStart,
    fetchPackagingPackagingtypeItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `packagingtype_item`),
    fetchPackagingPackagingtypeItemFailure: stateError,

    // PACKAGINGTYPE CREATE ITEM
    createPackagingPackagingtypeItemStart: stateLoadingStart,
    createPackagingPackagingtypeItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `packagingtype_item`),
    createPackagingPackagingtypeItemFailure: stateError,

    // PACKAGINGTYPE UPDATE ITEM
    updatePackagingPackagingtypeItemStart: stateLoadingStart,
    updatePackagingPackagingtypeItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `packagingtype_item`),
    updatePackagingPackagingtypeItemFailure: stateError,

    // PACKAGINGTYPE DELETE ITEM
    deletePackagingPackagingtypeItemStart: stateLoadingStart,
    deletePackagingPackagingtypeItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_packagingtype`),
    deletePackagingPackagingtypeItemFailure: stateError,


    // PACKAGING REDUCER FUNCTION

    // PACKAGING FETCH LIST
    fetchPackagingPackagingListStart: stateLoadingStart,
    fetchPackagingPackagingListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `packaging_list`),
    fetchPackagingPackagingListFailure: stateError,

    // PACKAGING FETCH ITEM
    fetchPackagingPackagingItemStart: stateLoadingStart,
    fetchPackagingPackagingItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `packaging_item`),
    fetchPackagingPackagingItemFailure: stateError,

    // PACKAGING CREATE ITEM
    createPackagingPackagingItemStart: stateLoadingStart,
    createPackagingPackagingItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `packaging_item`),
    createPackagingPackagingItemFailure: stateError,

    // PACKAGING UPDATE ITEM
    updatePackagingPackagingItemStart: stateLoadingStart,
    updatePackagingPackagingItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `packaging_item`),
    updatePackagingPackagingItemFailure: stateError,

    // PACKAGING DELETE ITEM
    deletePackagingPackagingItemStart: stateLoadingStart,
    deletePackagingPackagingItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_packaging`),
    deletePackagingPackagingItemFailure: stateError,


    // PACKAGEDITEM REDUCER FUNCTION

    // PACKAGEDITEM FETCH LIST
    fetchPackagingPackageditemListStart: stateLoadingStart,
    fetchPackagingPackageditemListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `packageditem_list`),
    fetchPackagingPackageditemListFailure: stateError,

    // PACKAGEDITEM FETCH ITEM
    fetchPackagingPackageditemItemStart: stateLoadingStart,
    fetchPackagingPackageditemItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `packageditem_item`),
    fetchPackagingPackageditemItemFailure: stateError,

    // PACKAGEDITEM CREATE ITEM
    createPackagingPackageditemItemStart: stateLoadingStart,
    createPackagingPackageditemItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `packageditem_item`),
    createPackagingPackageditemItemFailure: stateError,

    // PACKAGEDITEM UPDATE ITEM
    updatePackagingPackageditemItemStart: stateLoadingStart,
    updatePackagingPackageditemItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `packageditem_item`),
    updatePackagingPackageditemItemFailure: stateError,

    // PACKAGEDITEM DELETE ITEM
    deletePackagingPackageditemItemStart: stateLoadingStart,
    deletePackagingPackageditemItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_packageditem`),
    deletePackagingPackageditemItemFailure: stateError,


  },
});

export const {
  fetchPackagingPackagingtypeListStart, fetchPackagingPackagingListStart, fetchPackagingPackageditemListStart,
  fetchPackagingPackagingtypeListSuccess, fetchPackagingPackagingListSuccess, fetchPackagingPackageditemListSuccess,
  fetchPackagingPackagingtypeListFailure, fetchPackagingPackagingListFailure, fetchPackagingPackageditemListFailure,
  fetchPackagingPackagingtypeItemStart, fetchPackagingPackagingItemStart, fetchPackagingPackageditemItemStart,
  fetchPackagingPackagingtypeItemSuccess, fetchPackagingPackagingItemSuccess, fetchPackagingPackageditemItemSuccess,
  fetchPackagingPackagingtypeItemFailure, fetchPackagingPackagingItemFailure, fetchPackagingPackageditemItemFailure,
  createPackagingPackagingtypeItemStart, createPackagingPackagingItemStart, createPackagingPackageditemItemStart,
  createPackagingPackagingtypeItemSuccess, createPackagingPackagingItemSuccess, createPackagingPackageditemItemSuccess,
  createPackagingPackagingtypeItemFailure, createPackagingPackagingItemFailure, createPackagingPackageditemItemFailure,
  updatePackagingPackagingtypeItemStart, updatePackagingPackagingItemStart, updatePackagingPackageditemItemStart,
  updatePackagingPackagingtypeItemSuccess, updatePackagingPackagingItemSuccess, updatePackagingPackageditemItemSuccess,
  updatePackagingPackagingtypeItemFailure, updatePackagingPackagingItemFailure, updatePackagingPackageditemItemFailure,
  deletePackagingPackagingtypeItemStart, deletePackagingPackagingItemStart, deletePackagingPackageditemItemStart,
  deletePackagingPackagingtypeItemSuccess, deletePackagingPackagingItemSuccess, deletePackagingPackageditemItemSuccess,
  deletePackagingPackagingtypeItemFailure, deletePackagingPackagingItemFailure, deletePackagingPackageditemItemFailure
} = slice.actions;

export default slice.reducer;
