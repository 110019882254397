import { createSlice } from '@reduxjs/toolkit';

const stateLoadingStart = (state, action) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: true, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: null };
}
const stateError = (state, action) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: false, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: action.payload.error };
}
const stateSuccess = (state, action, key) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: false, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: null, [key]: action.payload.data };
}

export const slice = createSlice({
  name: 'order',
  initialState: { loading: false },
  reducers: {
    
    // ORDER REDUCER FUNCTION

    // ORDER FETCH LIST
    fetchOrderOrderListStart: stateLoadingStart,
    fetchOrderOrderListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `order_list`),
    fetchOrderOrderListFailure: stateError,

    // ORDER FETCH ITEM
    fetchOrderOrderItemStart: stateLoadingStart,
    fetchOrderOrderItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `order_item`),
    fetchOrderOrderItemFailure: stateError,

    // ORDER CREATE ITEM
    createOrderOrderItemStart: stateLoadingStart,
    createOrderOrderItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `order_item`),
    createOrderOrderItemFailure: stateError,

    // ORDER UPDATE ITEM
    updateOrderOrderItemStart: stateLoadingStart,
    updateOrderOrderItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `order_item`),
    updateOrderOrderItemFailure: stateError,

    // ORDER DELETE ITEM
    deleteOrderOrderItemStart: stateLoadingStart,
    deleteOrderOrderItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_order`),
    deleteOrderOrderItemFailure: stateError,


    // ORDERITEM REDUCER FUNCTION

    // ORDERITEM FETCH LIST
    fetchOrderOrderitemListStart: stateLoadingStart,
    fetchOrderOrderitemListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `orderitem_list`),
    fetchOrderOrderitemListFailure: stateError,

    // ORDERITEM FETCH ITEM
    fetchOrderOrderitemItemStart: stateLoadingStart,
    fetchOrderOrderitemItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `orderitem_item`),
    fetchOrderOrderitemItemFailure: stateError,

    // ORDERITEM CREATE ITEM
    createOrderOrderitemItemStart: stateLoadingStart,
    createOrderOrderitemItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `orderitem_item`),
    createOrderOrderitemItemFailure: stateError,

    // ORDERITEM UPDATE ITEM
    updateOrderOrderitemItemStart: stateLoadingStart,
    updateOrderOrderitemItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `orderitem_item`),
    updateOrderOrderitemItemFailure: stateError,

    // ORDERITEM DELETE ITEM
    deleteOrderOrderitemItemStart: stateLoadingStart,
    deleteOrderOrderitemItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_orderitem`),
    deleteOrderOrderitemItemFailure: stateError,


  },
});

export const {
  fetchOrderOrderListStart, fetchOrderOrderitemListStart,
  fetchOrderOrderListSuccess, fetchOrderOrderitemListSuccess,
  fetchOrderOrderListFailure, fetchOrderOrderitemListFailure,
  fetchOrderOrderItemStart, fetchOrderOrderitemItemStart,
  fetchOrderOrderItemSuccess, fetchOrderOrderitemItemSuccess,
  fetchOrderOrderItemFailure, fetchOrderOrderitemItemFailure,
  createOrderOrderItemStart, createOrderOrderitemItemStart,
  createOrderOrderItemSuccess, createOrderOrderitemItemSuccess,
  createOrderOrderItemFailure, createOrderOrderitemItemFailure,
  updateOrderOrderItemStart, updateOrderOrderitemItemStart,
  updateOrderOrderItemSuccess, updateOrderOrderitemItemSuccess,
  updateOrderOrderItemFailure, updateOrderOrderitemItemFailure,
  deleteOrderOrderItemStart, deleteOrderOrderitemItemStart,
  deleteOrderOrderItemSuccess, deleteOrderOrderitemItemSuccess,
  deleteOrderOrderItemFailure, deleteOrderOrderitemItemFailure
} = slice.actions;

export default slice.reducer;
