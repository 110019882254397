import axios from '../utils/axios';

import {fetchStoreStoreListStart, fetchStoreStoreListSuccess, fetchStoreStoreListFailure} from '../features/store'
import {fetchStoreStoreItemStart, fetchStoreStoreItemSuccess, fetchStoreStoreItemFailure} from '../features/store'
import {createStoreStoreItemStart, createStoreStoreItemSuccess, createStoreStoreItemFailure} from '../features/store'
import {updateStoreStoreItemStart, updateStoreStoreItemSuccess, updateStoreStoreItemFailure} from '../features/store'
import {deleteStoreStoreItemStart, deleteStoreStoreItemSuccess, deleteStoreStoreItemFailure} from '../features/store'

import {fetchStoreRoomListStart, fetchStoreRoomListSuccess, fetchStoreRoomListFailure} from '../features/store'
import {fetchStoreRoomItemStart, fetchStoreRoomItemSuccess, fetchStoreRoomItemFailure} from '../features/store'
import {createStoreRoomItemStart, createStoreRoomItemSuccess, createStoreRoomItemFailure} from '../features/store'
import {updateStoreRoomItemStart, updateStoreRoomItemSuccess, updateStoreRoomItemFailure} from '../features/store'
import {deleteStoreRoomItemStart, deleteStoreRoomItemSuccess, deleteStoreRoomItemFailure} from '../features/store'


// STORE ACTIONS
export const fetchStoreStoreList = async (dispatch, info, key) => {
    try{
        dispatch(fetchStoreStoreListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/store/store/`, { params: info.params, headers: info.headers });
        dispatch(fetchStoreStoreListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchStoreStoreListFailure({key, error: error.message}));
    }
}
export const fetchStoreStoreItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchStoreStoreItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/store/store/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchStoreStoreItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchStoreStoreItemFailure({key, error: error.message}));
    }
}
export const createStoreStoreItem = async (dispatch, info, key) => {
    try{
        dispatch(createStoreStoreItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/store/store/`, info.payload, { headers: info.headers });
        dispatch(createStoreStoreItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createStoreStoreItemFailure({key, error: error.message}));
    }
}
export const updateStoreStoreItem = async (dispatch, info, key) => {
    try{
        dispatch(updateStoreStoreItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/store/store/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateStoreStoreItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateStoreStoreItemFailure({key, error: error.message}));
    }
}
export const deleteStoreStoreItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteStoreStoreItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/store/store/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteStoreStoreItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteStoreStoreItemFailure({key, error: error.message}));
    }
}


// ROOM ACTIONS
export const fetchStoreRoomList = async (dispatch, info, key) => {
    try{
        dispatch(fetchStoreRoomListStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/store/room/`, { params: info.params, headers: info.headers });
        dispatch(fetchStoreRoomListSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchStoreRoomListFailure({key, error: error.message}));
    }
}
export const fetchStoreRoomItem = async (dispatch, info, key) => {
    try{
        dispatch(fetchStoreRoomItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).get(`/store/room/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { params: info.params, headers: info.headers });

        dispatch(fetchStoreRoomItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(fetchStoreRoomItemFailure({key, error: error.message}));
    }
}
export const createStoreRoomItem = async (dispatch, info, key) => {
    try{
        dispatch(createStoreRoomItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).post(`/store/room/`, info.payload, { headers: info.headers });
        dispatch(createStoreRoomItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(createStoreRoomItemFailure({key, error: error.message}));
    }
}
export const updateStoreRoomItem = async (dispatch, info, key) => {
    try{
        dispatch(updateStoreRoomItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).patch(`/store/room/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, info.payload, { headers: info.headers });
        dispatch(updateStoreRoomItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(updateStoreRoomItemFailure({key, error: error.message}));
    }
}
export const deleteStoreRoomItem = async (dispatch, info, key) => {
    try{
        dispatch(deleteStoreRoomItemStart({key}));
        const response = await axios(localStorage.getItem('FABU')).delete(`/store/room/${info.params.lookup_field?info.params[info.params.lookup_field]:(info.params.id || info.params.slug)}/`, { headers: info.headers });
        dispatch(deleteStoreRoomItemSuccess({key, data:response.data}));
    } catch (error) {
        dispatch(deleteStoreRoomItemFailure({key, error: error.message}));
    }
}




