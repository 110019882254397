import { createSlice } from '@reduxjs/toolkit';

const stateLoadingStart = (state, action) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: true, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: null };
}
const stateError = (state, action) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: false, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: action.payload.error };
}
const stateSuccess = (state, action, key) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: false, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: null, [key]: action.payload.data };
}

export const slice = createSlice({
  name: 'feedback',
  initialState: { loading: false },
  reducers: {
    
    // FEEDBACK REDUCER FUNCTION

    // FEEDBACK FETCH LIST
    fetchFeedbackFeedbackListStart: stateLoadingStart,
    fetchFeedbackFeedbackListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `feedback_list`),
    fetchFeedbackFeedbackListFailure: stateError,

    // FEEDBACK FETCH ITEM
    fetchFeedbackFeedbackItemStart: stateLoadingStart,
    fetchFeedbackFeedbackItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `feedback_item`),
    fetchFeedbackFeedbackItemFailure: stateError,

    // FEEDBACK CREATE ITEM
    createFeedbackFeedbackItemStart: stateLoadingStart,
    createFeedbackFeedbackItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `feedback_item`),
    createFeedbackFeedbackItemFailure: stateError,

    // FEEDBACK UPDATE ITEM
    updateFeedbackFeedbackItemStart: stateLoadingStart,
    updateFeedbackFeedbackItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `feedback_item`),
    updateFeedbackFeedbackItemFailure: stateError,

    // FEEDBACK DELETE ITEM
    deleteFeedbackFeedbackItemStart: stateLoadingStart,
    deleteFeedbackFeedbackItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_feedback`),
    deleteFeedbackFeedbackItemFailure: stateError,


  },
});

export const {
  fetchFeedbackFeedbackListStart,
  fetchFeedbackFeedbackListSuccess,
  fetchFeedbackFeedbackListFailure,
  fetchFeedbackFeedbackItemStart,
  fetchFeedbackFeedbackItemSuccess,
  fetchFeedbackFeedbackItemFailure,
  createFeedbackFeedbackItemStart,
  createFeedbackFeedbackItemSuccess,
  createFeedbackFeedbackItemFailure,
  updateFeedbackFeedbackItemStart,
  updateFeedbackFeedbackItemSuccess,
  updateFeedbackFeedbackItemFailure,
  deleteFeedbackFeedbackItemStart,
  deleteFeedbackFeedbackItemSuccess,
  deleteFeedbackFeedbackItemFailure
} = slice.actions;

export default slice.reducer;
