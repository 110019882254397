import { createSlice } from '@reduxjs/toolkit';

const stateLoadingStart = (state, action) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: true, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: null };
}
const stateError = (state, action) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: false, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: action.payload.error };
}
const stateSuccess = (state, action, key) => {
    return { ...state, [`${action.payload.key ? `${action.payload.key}_loading`:'loading'}`]: false, [`${action.payload.key ? `${action.payload.key}_error`:'error'}`]: null, [key]: action.payload.data };
}

export const slice = createSlice({
  name: 'store',
  initialState: { loading: false },
  reducers: {
    
    // STORE REDUCER FUNCTION

    // STORE FETCH LIST
    fetchStoreStoreListStart: stateLoadingStart,
    fetchStoreStoreListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `store_list`),
    fetchStoreStoreListFailure: stateError,

    // STORE FETCH ITEM
    fetchStoreStoreItemStart: stateLoadingStart,
    fetchStoreStoreItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `store_item`),
    fetchStoreStoreItemFailure: stateError,

    // STORE CREATE ITEM
    createStoreStoreItemStart: stateLoadingStart,
    createStoreStoreItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `store_item`),
    createStoreStoreItemFailure: stateError,

    // STORE UPDATE ITEM
    updateStoreStoreItemStart: stateLoadingStart,
    updateStoreStoreItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `store_item`),
    updateStoreStoreItemFailure: stateError,

    // STORE DELETE ITEM
    deleteStoreStoreItemStart: stateLoadingStart,
    deleteStoreStoreItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_store`),
    deleteStoreStoreItemFailure: stateError,


    // ROOM REDUCER FUNCTION

    // ROOM FETCH LIST
    fetchStoreRoomListStart: stateLoadingStart,
    fetchStoreRoomListSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `room_list`),
    fetchStoreRoomListFailure: stateError,

    // ROOM FETCH ITEM
    fetchStoreRoomItemStart: stateLoadingStart,
    fetchStoreRoomItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `room_item`),
    fetchStoreRoomItemFailure: stateError,

    // ROOM CREATE ITEM
    createStoreRoomItemStart: stateLoadingStart,
    createStoreRoomItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `room_item`),
    createStoreRoomItemFailure: stateError,

    // ROOM UPDATE ITEM
    updateStoreRoomItemStart: stateLoadingStart,
    updateStoreRoomItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `room_item`),
    updateStoreRoomItemFailure: stateError,

    // ROOM DELETE ITEM
    deleteStoreRoomItemStart: stateLoadingStart,
    deleteStoreRoomItemSuccess: (state, action) => stateSuccess(state, action, action.payload.key ? action.payload.key : `deleted_room`),
    deleteStoreRoomItemFailure: stateError,


  },
});

export const {
  fetchStoreStoreListStart, fetchStoreRoomListStart,
  fetchStoreStoreListSuccess, fetchStoreRoomListSuccess,
  fetchStoreStoreListFailure, fetchStoreRoomListFailure,
  fetchStoreStoreItemStart, fetchStoreRoomItemStart,
  fetchStoreStoreItemSuccess, fetchStoreRoomItemSuccess,
  fetchStoreStoreItemFailure, fetchStoreRoomItemFailure,
  createStoreStoreItemStart, createStoreRoomItemStart,
  createStoreStoreItemSuccess, createStoreRoomItemSuccess,
  createStoreStoreItemFailure, createStoreRoomItemFailure,
  updateStoreStoreItemStart, updateStoreRoomItemStart,
  updateStoreStoreItemSuccess, updateStoreRoomItemSuccess,
  updateStoreStoreItemFailure, updateStoreRoomItemFailure,
  deleteStoreStoreItemStart, deleteStoreRoomItemStart,
  deleteStoreStoreItemSuccess, deleteStoreRoomItemSuccess,
  deleteStoreStoreItemFailure, deleteStoreRoomItemFailure
} = slice.actions;

export default slice.reducer;
